import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { map, filter, retry, catchError } from 'rxjs/operators';
import { configData } from '../../core/config';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  public response: any;
  public userData: any;
  public location:any;
  constructor(
    private http: HttpClient,public toastr:ToastrService
  ) { }
   public headerstr = new HttpHeaders({ "Authorization": sessionStorage.getItem("key") })
 
   
  //==========================================
  // getmethod(url) {
  //   return this.http.get(url, { headers: this.headerstr }).pipe(
  //     map(resp => this.response = resp)
  //   )
  // }
  // postmethod(data,url) {
  //   return this.http.post(url,data, { headers: this.headerstr }).pipe(
  //     map(resp => this.response = resp)
  //   )
  // }
  //=========================================
  getServiceAll(apiurl): Observable<any> {
    return this.http.get(apiurl, { headers: this.headerstr }).pipe(
      map(resp => this.response = resp)
    )
  }
  getServiceById(url, id): Observable<any> {
    return this.http.get(url, id).pipe(
      map(resp => this.response = resp)
    )
  }
  customGetService(url, metaData): Observable<any> {
    console.log("metaData", metaData);
    return this.http.get(url, metaData).pipe(
      map(resp => this.response = resp)
    )
  }
  postService(url, data): Observable<any> {
    return this.http.post(url, data).pipe(
      map(resp => this.response = resp)
    )
  }
  putService(url, data): Observable<any> {
    return this.http.put(url, data).pipe(
      map(resp => this.response = resp)
    )
  }
  deleteService(url, data): Observable<any> {
    return this.http.put(url, data).pipe(
      map(resp => this.response = resp)
    )
  }
  deleteServiceById(url): Observable<any> {
    return this.http.delete(url).pipe(
      map(resp => this.response = resp)
    )
  }

  loginService(url, data): Observable<any> {
    let httpHeaders = new HttpHeaders({
      "userName": data.email,
      "password": data.password,

    });
    return this.http.post(url, null, { headers: httpHeaders }).pipe(
      map(resp => this.response = resp)
    ).pipe(
      catchError(this.handleError))
  }


  getMails() {
    return this.http.get<any[]>('/api/mails');
  }
  getCountries() {
    return this.http.get<any[]>('/api/countries');
  }
  getProducts() { 
    return this.http.get<any[]>('api/products');
  }
  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error.status); // for demo purposes only
    return Promise.reject(error.status);
  }
  globalErrorHandler(erroCode) {
    if (erroCode === 400) {
      // this.doErrorAlert("ios-close-circle-outline", "Access is denied due to invalid PIN");
      // this._alert.showMessage("Invalid Details", "error");
    } else if (erroCode === 500) {
      //  this._alert.showMessage("Unable to process your request and try again", "error");
    } else if (erroCode === 0) {
      this.toastr.warning(
        "No Internet connection found from server side. Check your connection or please try again",
        "error"
      );
    } else if (erroCode === 503) {
      // this._alert.showMessage("HTTP Error 503. The service is unavailable.", "error");
    } else if (erroCode === 401) {
      this.toastr.warning(
        "Authorization has been denied for invalid credentials please give validate credentials.",
        "error"
      );
    }
  }
}
