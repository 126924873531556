import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { SearchService } from '../../services/search.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Data_Service } from '../../services/data_service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  notifications: any[];
  projectformongo:any;
  role:any;
  showNotificationBox: boolean = false;

  // Method to toggle the notification box visibility
  openNotificationPage() {
    // Toggle the value of showNotificationBox
    this.showNotificationBox = !this.showNotificationBox;
  }
  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private auth: AuthService,
    public router:Router,
    public _data:Data_Service
  )
   {
    this.projectformongo = localStorage.getItem("projectname");
    this.role = localStorage.getItem("role");
    this._dogetnotifications();
    // this._dogetassamnotifications();
    // this._dogetassamnotificationscount();
   
    // this.notifications = [
    //   {
    //     icon: 'icon-message-square',
    //     title: 'New message',
    //     badge: '3',
    //     text: 'James: Hey! are you busy?',
    //     time: new Date(),
    //     status: 'primary',
    //     link: '/chat'
    //   },
    //   {
    //     icon: 'icon-file-plus',
    //     title: 'New order received',
    //     badge: '$4036',
    //     text: '1 Headphone, 3 iPhone x',
    //     time: new Date('11/11/2018'),
    //     status: 'success',
    //     link: '/tables/full'
    //   },
    //   {
    //     icon: 'icon-box',
    //     title: '4 Products are out of stock',
    //     badge: '4',
    //     text: 'Headphone E67, R98, XL90, Q77',
    //     time: new Date('11/10/2018'),
    //     status: 'danger',
    //     link: '/tables/list'
    //   },
    //   {
    //     icon: 'icon-server',
    //     title: 'Server up!',
    //     badge: 'New',
    //     text: 'Server rebooted successfully',
    //     time: new Date('11/08/2018'),
    //     status: 'success',
    //     link: '/dashboard/v2'
    //   },
    //   {
    //     icon: 'icon-alert-triangle',
    //     title: 'Server down!',
    //     badge: 'New',
    //     text: 'Region 1: Server crashed!',
    //     time: new Date('11/06/2018'),
    //     status: 'danger',
    //     link: '/dashboard'
    //   }
    // ]
  }

  ngOnInit() {
    // if(this.projectformongo ==="'Assam_Railway'"){

    // } else if(this.projectformongo ==="'Marina_Square'"){
    //   this._dogetnotifications();
    // }
  }
  _dogetnotifications(){
    this._data.getmethod("notificationservicealertnew/" + this.projectformongo).subscribe(
      result =>{
        // console.log(result)
        this.notifications = result;
        
      },
      err =>{
        console.log(err)
      }
    )
  }
  // assamnotifications:any;
  // _dogetassamnotifications(){
  //   this._data.getmethod("asssm_notification/" + this.projectformongo).subscribe(
  //     result =>{
  //       // console.log("uha1234")
  //       // console.log(result)
  //       this.assamnotifications = result;
        
  //     },
  //     err =>{
  //       console.log(err)
  //     }
  //   )
  // }
  // noticount:any;
  // _dogetassamnotificationscount(){
  //   this._data.getmethod("asssm_notification_count/" + this.projectformongo).subscribe(
  //     result =>{
  //       if (result.length != 0) {
  //         this.noticount = result.count;
  //       } else {
  //         this.noticount = 0
  //       }
        
  //     },
  //     err =>{
  //       console.log(err)
  //     }
  //   )
  // }
  toggelSidebar() {
    let state = this.navService.sidebarState;
    if(!state.sidenavOpen) {
      return state.sidenavOpen = true;
    }
    if(state.childnavOpen && state.sidenavOpen) {
      return state.childnavOpen = false;
    }
    if(!state.childnavOpen && state.sidenavOpen) {
      return state.sidenavOpen = false;
    }
  }

  signout() {
    this.auth.signout();
    localStorage.clear();
  }
  profile(){
    this.router.navigateByUrl("/padmin/profile");
  }
  navigateToRanchiNotification() {
    this.router.navigateByUrl('/ranchi/ranchinotification');
  }


}
