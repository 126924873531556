import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http,Headers } from '@angular/http';
import * as jwt_decode from 'jwt-decode';

export const TOKEN_NAME: string = localStorage.getItem("key");
@Injectable()
export class AuthService {
  private url: string = 'api/auth';
  private headers = new Headers({ 'Content-Type': 'application/json' });
  private token = localStorage.getItem("key");
  constructor(private http: Http) { }

  getToken(): string {
    return localStorage.getItem("key");
  }

  setToken(token: string): void {
    localStorage.setItem("key", token);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate(token = localStorage.getItem("key"));
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  login(user): Promise<string> {
    return this.http
      .post(`${this.url}/login`, JSON.stringify(user), { headers: this.headers })
      .toPromise()
      .then(res => res.text());
  }
}