import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { configData } from "./config";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      request = request.clone({
        url: `${configData.apiUrl}${request.url}`,
        setHeaders: {
          'Content-Type': 'application/json',
        }
      });
    return next.handle(request);
  }
}

