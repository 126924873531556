import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem extends IMenuItem1 {
  type: string,       // Possible values: link/dropDown/icon/separator/extLink
  name?: string,      // Used as display text for item and title for separator type
  state?: string,     // Router state
  icon?: string,      // Material icon name
  tooltip?: string,   // Tooltip text
  disabled?: boolean, // If true, item will not be appeared in sidenav.
  sub?: IChildItem[], // Dropdown items
  badges?: IBadge[],
  active?: boolean
}
interface IMenuItem1 {
  type: string,       // Possible values: link/dropDown/icon/separator/extLink
  name?: string,      // Used as display text for item and title for separator type
  state?: string,     // Router state
  icon?: string,      // Material icon name
  tooltip?: string,   // Tooltip text
  disabled?: boolean, // If true, item will not be appeared in sidenav.
  sub?: IChildItem[], // Dropdown items
  badges?: IBadge[],
  active?: boolean
}

interface IMenuItem2 {
  type: string,       // Possible values: link/dropDown/icon/separator/extLink
  name?: string,      // Used as display text for item and title for separator type
  state?: string,     // Router state
  icon?: string,      // Material icon name
  tooltip?: string,   // Tooltip text
  disabled?: boolean, // If true, item will not be appeared in sidenav.
  sub?: IChildItem1[], // Dropdown items
  badges?: IBadge[],
  active?: boolean
}

interface IMenuItem3 {
  type: string,       // Possible values: link/dropDown/icon/separator/extLink
  name?: string,      // Used as display text for item and title for separator type
  state?: string,     // Router state
  icon?: string,      // Material icon name
  tooltip?: string,   // Tooltip text
  disabled?: boolean, // If true, item will not be appeared in sidenav.
  sub?: IChildItem1[], // Dropdown items
  badges?: IBadge[],
  active?: boolean
}


interface IMenuItem4 {
  type: string,       // Possible values: link/dropDown/icon/separator/extLink
  name?: string,      // Used as display text for item and title for separator type
  state?: string,     // Router state
  icon?: string,      // Material icon name
  tooltip?: string,   // Tooltip text
  disabled?: boolean, // If true, item will not be appeared in sidenav.
  sub?: IChildItem1[], // Dropdown items
  badges?: IBadge[],
  active?: boolean
}


interface IChildItem {
  type?: string,
  name: string,       // Display text
  state?: string,     // Router state
  icon?: string,
  sub?: IChildItem[],
  active?: boolean
}

interface IChildItem1 {
  type?: string,
  name: string,
  icon?: string,       // Display text
  state?: string,     // Router state
  sub?: IChildItem1[],
  active?: boolean
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

interface ISidebarState {
  sidenavOpen?: boolean;
  childnavOpen?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  Storage: any = {};
  project_name:any;
  sidemenu;
  public sidebarState: ISidebarState = {
    sidenavOpen: true,
    childnavOpen: false
  }

  constructor() { 
    this.Storage = localStorage.getItem("role");
    // console.log(this.Storage)
    this.project_name = localStorage.getItem("projectname");
// console.log(this.project_name)
     if (this.Storage === "padmin") {
      if (this.project_name === "Marina_Square") {
        
        this.sidemenu = this.defaultMenu;
      } 
      else if(this.project_name === "burjdaman"){
        this.sidemenu = this.adminMenu;
      } 
      else if(this.project_name === "CSIR"){
        this.sidemenu = this.assamMenu;
      } 
      else if(this.project_name === "AdityaBirla"){
        this.sidemenu = this.adityabirla;
      } 
      else if(this.project_name === "Gujarat"){
        this.sidemenu = this.gujaratMenu;
      }
      else if(this.project_name === "nikko_homes"){
        this.sidemenu = this.nikoohomes;
      }
      else if(this.project_name === "Nagaland"){
        this.sidemenu = this.nagalandMenu;
      }
      else if(this.project_name === "Evidence_Action"){
        this.sidemenu = this.evidenceMenu;
      }
      else if(this.project_name === "ahp_woods"){
        this.sidemenu = this.ahpwoodsMenu;
      }
      else if(this.project_name === "Ahad_opus"){
        this.sidemenu = this.ahadopus;
      }
      // else if(this.project_name === "ExozenOffice"){
      //   this.sidemenu = this.officeMenu;
      // }
      else if(this.project_name === "Namdharis"){
        this.sidemenu = this.namdhariMenu;
      }
      else if(this.project_name === "Namdharis"){
        this.sidemenu = this.namdhariMenu;
      } 
      // else if(this.project_name === "Ahad_Excellencia"){
      //   this.sidemenu = this.excellentiaMenu;
      // }
      else if(this.project_name === "Bm_Splendors_Park"){
        this.sidemenu = this.bmsplendorMenu;
      }


      else if(this.project_name === "Ahpa01"){
        this.sidemenu = this.ahpa01Menu;
      }

     
     // else if(this.project_name === "sethna_power_tower"){
      //  this.sidemenu = this.sptMenu;
      //}
      else if(this.project_name === "Evidence_Action_Admin"){
        this.sidemenu = this.eadminaddMenu;
      } 
      else if(this.project_name === "Madhya_pradesh"){
        this.sidemenu = this.madhyapradeshMenu;
      }
      else if(this.project_name === "Andhra_pradesh"){
        this.sidemenu = this.andhrapradeshMenu;
      }  

      else if(this.project_name === "Ranchi"){
        this.sidemenu = this.ranchiMenu;
      } 
      
      else if(this.project_name === "Chirugora"){
        this.sidemenu = this.chirugoraMenu;
      }  

      else if(this.project_name === "Pondugala"){
        this.sidemenu = this.pondugalaMenu;
      }  


      else if(this.project_name === "Jindal"){
        this.sidemenu = this.jindalMenu;
      }  

   
      else if(this.project_name === "Tripura"){
        this.sidemenu = this.tripuraMenu;
      }  


      
      


      else {
        this.sidemenu = this.zenadminMenu;
      }
      
    }  
    else if (this.Storage === "admin") {
      this.sidemenu = this.superadminMenu;
    }
  }

  defaultMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
        
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/padmin/dashboard', type: 'link' },
       
      ]
    },
    //customer
    {
      name: 'Customer',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Customer List', state: '/padmin/customerlist', type: 'link' },
      ]
    },
    //customer
    //master
    {
      name: 'Master',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-zap', name: 'Meter Master', state: '/padmin/metermaster', type: 'link' },
        { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/padmin/activeinactivemeters', type: 'link' },
        { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/padmin/assignunassignmeters', type: 'link' },
        { icon: 'icon-zap', name: 'Device Status', state: '/padmin/devicestatus', type: 'link' },
      ]
    },
    //master
    //Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
    //   ]
    // },
    //Tanker Management
    //Billing
    // {
    //   name: 'Billing',
    //   type: 'dropDown',
    //   icon: 'icon-file',
    //   sub: [
    //     { icon: 'icon-file', name: 'Billing history', state: '/padmin/customerbills', type: 'link' },
        
       
    //   ]
    // },
    //Billing
    //Helpdesk
    // {
    //   name: 'Helpdesk',
    //   type: 'dropDown',
    //   icon: 'icon-help-circle',
    //   sub: [
    //     { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/padmin/helpdesklist', type: 'link' },
              
    //   ]
    // },
    //Helpdesk
    //Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
        { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/padmin/reports', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/padmin/wingwisereports', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/padmin/monthwisereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/padmin/customdatereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'SAP Daily Report Export', state: '/padmin/dailyreports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Daily export reports', state: '/padmin/dailyexportreports', type: 'link' },
              
      ]
    },
    //Reports
  
  ]

  zenadminMenu: IMenuItem1[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/zenpadmin/zendashboard', type: 'link' },
    
      ]
    },
    //customer
    {
      name: 'Customer',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Customer List', state: '/zenpadmin/zencustomerlist', type: 'link' },
      ]
    },
    //customer
    //master
    {
      name: 'Master',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-zap', name: 'Meter Master', state: '/new/metermaster', type: 'link' },
        { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/new/activeinactivemeters', type: 'link' },
        { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/new/assignunassignmeters', type: 'link' },
        // { icon: 'icon-zap', name: 'Meter Status', state: '/new/meterstatus', type: 'link' },
        // { icon: 'icon-zap', name: 'Monthwise Active/InActive ', state: '/zenpadmin/monthwiseactiveinactive', type: 'link' },
      ]
    },
    //master
   // Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/zenpadmin/zenvendorlist', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/zenpadmin/zentankermanagementlist', type: 'link' },
       
    //   ]
    // },
    // Tanker Management
    //Billing
    {
      name: 'Billing',
      type: 'dropDown',
      icon: 'icon-file',
      sub: [
        { icon: 'icon-file', name: 'Customer Bills', state: '/zenpadmin/zencustomerbills', type: 'link' },
        { icon: 'icon-file', name: 'Meter Rate Card', state: '/zenpadmin/zenmeterratecard', type: 'link' },
      
       
      ]
    },
    //Billing
    //Helpdesk
    {
      name: 'Helpdesk',
      type: 'dropDown',
      icon: 'icon-help-circle',
      sub: [
        { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/zenpadmin/zenhelpdesk', type: 'link' },
              
      ]
    },
    //Helpdesk
    // Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
        { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/zenpadmin/zenreports', type: 'link' },
       // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/zenpadmin/zenwingwisereports', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/zenpadmin/zenmonthwisereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/zenpadmin/zencustomdatereports', type: 'link' },
              
      ]
    },
    //Reports
    // {
    //   name: 'UI kits',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Alerts', state: '/uikits/alerts', type: 'link' },
    //     { icon: 'icon-chevrons-down', name: 'Accordions', state: '/uikits/accordions', type: 'link' },
    //     { icon: 'icon-award', name: 'Badges', state: '/uikits/badges', type: 'link' },
    //     { icon: 'icon-arrow-right-circle', name: 'Buttons', state: '/uikits/buttons', type: 'link' },
    //     { icon: 'icon-copy1', name: 'Cards', state: '/uikits/cards', type: 'link' },
    //     { icon: 'icon-box', name: 'Cards widget', state: '/uikits/cards-widget', type: 'link' },
    //     { icon: 'icon-bar-chart', name: 'Cards metrics', state: '/uikits/cards-metrics', type: 'link' },
    //     { icon: 'icon-shopping-bag', name: 'Cards ecommerce', state: '/uikits/cards-ecommerce', type: 'link' },
    //     { icon: 'icon-square', name: 'Modals', state: '/uikits/modals', type: 'link' },
    //     { icon: 'icon-loader', name: 'Loaders', state: '/uikits/loaders', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Ecommerce',
    //   type: 'dropDown',
    //   icon: 'icon-shopping-bag',
    //   sub: [
    //     { icon: 'icon-package', name: 'Products', state: '/ecommerce/products', type: 'link' },
    //     { icon: 'icon-book-open', name: 'Product Details', state: '/ecommerce/product-details', type: 'link' },
    //     { icon: 'icon-shopping-cart', name: 'Cart', state: '/ecommerce/cart', type: 'link' },
    //     { icon: 'icon-credit-card1', name: 'Checkout', state: '/ecommerce/checkout', type: 'link' }
    //   ]
    // },
    // {
    //   name: 'Forms',
    //   type: 'dropDown',
    //   icon: 'icon-edit-pencil',
    //   sub: [
    //     { icon: 'icon-book-open', name: 'Basic Inputs', state: '/forms/basic', type: 'link' },
    //     { icon: 'icon-bookmark1', name: 'Tag Input', state: '/forms/tag-input', type: 'link' },
    //     { icon: 'icon-edit', name: 'Input Group', state: '/forms/input-group', type: 'link' },
    //     { icon: 'icon-grid', name: 'Input Grids', state: '/forms/input-grids', type: 'link' },
    //     { icon: 'icon-zap', name: 'Actions', state: '/forms/actions', type: 'link' },
    //     { icon: 'icon-magic-wand', name: 'Wizards', state: '/forms/wizard', type: 'link' },
    //     { icon: 'icon-check-square', name: 'Validation', state: '/forms/validation', type: 'link' },
    //     { icon: 'icon-layout', name: 'Layouts', state: '/forms/layouts', type: 'link' },
    //     { icon: 'icon-crop1', name: 'Image Cropper', state: '/forms/img-cropper', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Charts',
    //   type: 'dropDown',
    //   icon: 'icon-chart-bar',
    //   sub: [
    //     { icon: 'icon-activity', name: 'Line Charts', state: '/charts/line', type: 'link' },
    //     { icon: 'icon-chart', name: 'Area Charts', state: '/charts/area', type: 'link' },
    //     { icon: 'icon-bar-chart-2', name: 'Bar Charts', state: '/charts/bar', type: 'link' },
    //     { icon: 'icon-pie-chart1', name: 'Pai & Donut', state: '/charts/pai', type: 'link' },
    //     { icon: 'icon-time', name: 'Timeriver', state: '/charts/timeriver', type: 'link' },
    //     { icon: 'icon-sliders', name: 'Candlestick', state: '/charts/candlestick', type: 'link' },
    //     { icon: 'icon-sun1', name: 'Heatmap', state: '/charts/heatmap', type: 'link' },
    //     { icon: 'icon-load-balancer', name: 'Treemap', state: '/charts/treemap', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Data Tables',
    //   type: 'dropDown',
    //   icon: 'icon-view-column',
    //   sub: [
    //     { icon: 'icon-align-justify', name: 'Basic Tables', state: '/tables/basic', type: 'link' },
    //     { icon: 'icon-list1', name: 'List', state: '/tables/list', type: 'link' },
    //     { icon: 'icon-maximize', name: 'Fullscreen', state: '/tables/full', type: 'link' },
    //     { icon: 'icon-book-open', name: 'Paging', state: '/tables/paging', type: 'link' },
    //     { icon: 'icon-filter1', name: 'Filter', state: '/tables/filter', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Apps',
    //   type: 'dropDown',
    //   icon: 'icon-inbox-download',
    //   sub: [
    //     { icon: 'icon-inbox', name: 'Inbox', state: '/inbox', type: 'link' },
    //     { icon: 'icon-message-square', name: 'Chat', state: '/chat', type: 'link' },
    //     { icon: 'icon-calendar1', name: 'Calendar', state: '/calendar', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Sessions',
    //   type: 'dropDown',
    //   icon: 'icon-user-solid-circle',
    //   sub: [
    //     { icon: 'icon-log-in', name: 'Sign up', state: '/sessions/signup', type: 'link' },
    //     { icon: 'icon-log-out', name: 'Sign in', state: '/sessions/signin', type: 'link' },
    //     { icon: 'icon-users1', name: 'Forgot', state: '/sessions/forgot', type: 'link' }
    //   ]
    // },
    // {
    //   name: 'Others',
    //   type: 'dropDown',
    //   icon: 'icon-stroke-width',
    //   sub: [
    //     { icon: 'icon-window', name: 'Not found', state: '/others/404', type: 'link' }
    //   ]
    // }
  ]

  superadminMenu: IMenuItem1[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/sadmin/sadmindashboard', type: 'link' },
    
      ]
    },
    {
      name: 'Helpdesk',
      type: 'dropDown',
      icon: 'icon-help-circle',
      sub: [
        { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/sadmin/sadminhelpdesk', type: 'link' },
              
      ]
    },
   
    //master
    {
      name: 'Master',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-layers1', name: 'Add Device', state: '/sadmin/sadminadddevice', type: 'link' },
        { icon: 'icon-layers1', name: 'Active&Inactive', state: '/sadmin/activeinactive', type: 'link' },

      ]
    },
    //master
     //customer
     {
      name: 'Customer',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Add Customer', state: '/sadmin/sadminaddcustomer', type: 'link' },
      ]
    },
    //customer
     //customer
     {
      name: 'Projects',
      type: 'dropDown',
      icon: 'icon-view-column',
      sub: [
        { icon: 'icon-view-column', name: 'Add Project', state: '/sadmin/sadminaddproject', type: 'link' },
      ]
    },
    //Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
        { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/sadmin/sadminreports', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/sadmin/sadminwingwisereports', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/sadmin/sadminmonthwisereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/sadmin/sadmincustomdatereports', type: 'link' },
              
      ]
    },
    //Reports
    //Device health check
    // {
    //   name: 'Device Check',
    //   type: 'dropDown',
    //   icon: 'icon-users',
    //   sub: [
    //     { icon: 'icon-user', name: 'Real Time Check', state: '/sadmin/sadminrealtimecheck', type: 'link' },
    //     { icon: 'icon-user', name: 'Deviation Checks', state: '/sadmin/sadmindeviationcheck', type: 'link' },
    //   ]
    // },
    {
      name: 'Device Check',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Over Consumption', state: '/sadmin/overconsumption', type: 'link' },
        { icon: 'icon-user', name: 'Deviation Check', state: '/sadmin/deviationcheck', type: 'link' },
      ]
    },
    {
      name: 'Track Here',
      type: 'dropDown',
      icon: 'icon-map1',
      sub: [
        { icon: 'icon-map1', name: 'Track Here', state: '/sadmin/sadmintrackhere', type: 'link' },
        
      ]
    },
    // {
    //   name: 'Restart Status',
    //   type: 'dropDown',
    //   icon: 'icon-meter',
    //   sub: [
    //     { icon: 'icon-meter', name: 'Restart status', state: '/sadmin/devicerestartstatus', type: 'link' },
    //     { icon: 'icon-meter', name: 'Ontime status', state: '/sadmin/ontimestatus', type: 'link' },
        
    //   ]
    // },
    // {
    //   name: 'settings',
    //   type: 'dropDown',
    //   icon: 'icon-settings',
    //   sub: [
    //     { icon: 'icon-user', name: 'Project Type', state: '/sadmin/projecttype', type: 'link' },
    //     { icon: 'icon-user', name: 'AMC Type', state: '/sadmin/amctype', type: 'link' },
        
    //   ]
    // },
    //Device health check
    //customer
    //Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
    //   ]
    // },
    //Tanker Management
    //Billing
    // {
    //   name: 'Billing',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Billing history', state: '/padmin/customerbills', type: 'link' },
      
       
    //   ]
    // },
    //Billing
    //Helpdesk
    // {
    //   name: 'Helpdesk',
    //   type: 'dropDown',
    //   icon: 'icon-help-circle',
    //   sub: [
    //     { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/padmin/helpdesklist', type: 'link' },
              
    //   ]
    // },
    //Helpdesk
    //Reports
    // {
    //   name: 'Reports',
    //   type: 'dropDown',
    //   icon: 'icon-chart-bar',
    //   sub: [
    //     { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/padmin/reports', type: 'link' },
    //     { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/padmin/wingwisereports', type: 'link' },
    //     { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/padmin/monthwisereports', type: 'link' },
    //      { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/padmin/customdatereports', type: 'link' },
              
    //   ]
    // },
    //Reports
    // {
    //   name: 'UI kits',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Alerts', state: '/uikits/alerts', type: 'link' },
    //     { icon: 'icon-chevrons-down', name: 'Accordions', state: '/uikits/accordions', type: 'link' },
    //     { icon: 'icon-award', name: 'Badges', state: '/uikits/badges', type: 'link' },
    //     { icon: 'icon-arrow-right-circle', name: 'Buttons', state: '/uikits/buttons', type: 'link' },
    //     { icon: 'icon-copy1', name: 'Cards', state: '/uikits/cards', type: 'link' },
    //     { icon: 'icon-box', name: 'Cards widget', state: '/uikits/cards-widget', type: 'link' },
    //     { icon: 'icon-bar-chart', name: 'Cards metrics', state: '/uikits/cards-metrics', type: 'link' },
    //     { icon: 'icon-shopping-bag', name: 'Cards ecommerce', state: '/uikits/cards-ecommerce', type: 'link' },
    //     { icon: 'icon-square', name: 'Modals', state: '/uikits/modals', type: 'link' },
    //     { icon: 'icon-loader', name: 'Loaders', state: '/uikits/loaders', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Ecommerce',
    //   type: 'dropDown',
    //   icon: 'icon-shopping-bag',
    //   sub: [
    //     { icon: 'icon-package', name: 'Products', state: '/ecommerce/products', type: 'link' },
    //     { icon: 'icon-book-open', name: 'Product Details', state: '/ecommerce/product-details', type: 'link' },
    //     { icon: 'icon-shopping-cart', name: 'Cart', state: '/ecommerce/cart', type: 'link' },
    //     { icon: 'icon-credit-card1', name: 'Checkout', state: '/ecommerce/checkout', type: 'link' }
    //   ]
    // },
    // {
    //   name: 'Forms',
    //   type: 'dropDown',
    //   icon: 'icon-edit-pencil',
    //   sub: [
    //     { icon: 'icon-book-open', name: 'Basic Inputs', state: '/forms/basic', type: 'link' },
    //     { icon: 'icon-bookmark1', name: 'Tag Input', state: '/forms/tag-input', type: 'link' },
    //     { icon: 'icon-edit', name: 'Input Group', state: '/forms/input-group', type: 'link' },
    //     { icon: 'icon-grid', name: 'Input Grids', state: '/forms/input-grids', type: 'link' },
    //     { icon: 'icon-zap', name: 'Actions', state: '/forms/actions', type: 'link' },
    //     { icon: 'icon-magic-wand', name: 'Wizards', state: '/forms/wizard', type: 'link' },
    //     { icon: 'icon-check-square', name: 'Validation', state: '/forms/validation', type: 'link' },
    //     { icon: 'icon-layout', name: 'Layouts', state: '/forms/layouts', type: 'link' },
    //     { icon: 'icon-crop1', name: 'Image Cropper', state: '/forms/img-cropper', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Charts',
    //   type: 'dropDown',
    //   icon: 'icon-chart-bar',
    //   sub: [
    //     { icon: 'icon-activity', name: 'Line Charts', state: '/charts/line', type: 'link' },
    //     { icon: 'icon-chart', name: 'Area Charts', state: '/charts/area', type: 'link' },
    //     { icon: 'icon-bar-chart-2', name: 'Bar Charts', state: '/charts/bar', type: 'link' },
    //     { icon: 'icon-pie-chart1', name: 'Pai & Donut', state: '/charts/pai', type: 'link' },
    //     { icon: 'icon-time', name: 'Timeriver', state: '/charts/timeriver', type: 'link' },
    //     { icon: 'icon-sliders', name: 'Candlestick', state: '/charts/candlestick', type: 'link' },
    //     { icon: 'icon-sun1', name: 'Heatmap', state: '/charts/heatmap', type: 'link' },
    //     { icon: 'icon-load-balancer', name: 'Treemap', state: '/charts/treemap', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Data Tables',
    //   type: 'dropDown',
    //   icon: 'icon-view-column',
    //   sub: [
    //     { icon: 'icon-align-justify', name: 'Basic Tables', state: '/tables/basic', type: 'link' },
    //     { icon: 'icon-list1', name: 'List', state: '/tables/list', type: 'link' },
    //     { icon: 'icon-maximize', name: 'Fullscreen', state: '/tables/full', type: 'link' },
    //     { icon: 'icon-book-open', name: 'Paging', state: '/tables/paging', type: 'link' },
    //     { icon: 'icon-filter1', name: 'Filter', state: '/tables/filter', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Apps',
    //   type: 'dropDown',
    //   icon: 'icon-inbox-download',
    //   sub: [
    //     { icon: 'icon-inbox', name: 'Inbox', state: '/inbox', type: 'link' },
    //     { icon: 'icon-message-square', name: 'Chat', state: '/chat', type: 'link' },
    //     { icon: 'icon-calendar1', name: 'Calendar', state: '/calendar', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Sessions',
    //   type: 'dropDown',
    //   icon: 'icon-user-solid-circle',
    //   sub: [
    //     { icon: 'icon-log-in', name: 'Sign up', state: '/sessions/signup', type: 'link' },
    //     { icon: 'icon-log-out', name: 'Sign in', state: '/sessions/signin', type: 'link' },
    //     { icon: 'icon-users1', name: 'Forgot', state: '/sessions/forgot', type: 'link' }
    //   ]
    // },
    // {
    //   name: 'Others',
    //   type: 'dropDown',
    //   icon: 'icon-stroke-width',
    //   sub: [
    //     { icon: 'icon-window', name: 'Not found', state: '/others/404', type: 'link' }
    //   ]
    // }
  ]
  adminMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/admin/dashboard', type: 'link' },
      
      ]
    },
    //customer
    {
      name: 'Customer',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Customer List', state: '/admin/customerlist', type: 'link' },
      ]
    },
    //customer
    //master
    {
      name: 'Master',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-zap', name: 'Meter Master', state: '/admin/metermaster', type: 'link' },
        { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/admin/activeinactivemeters', type: 'link' },
        { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/admin/assignunassignmeters', type: 'link' },
      ]
    },
    //master
    //Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
    //   ]
    // },
    //Tanker Management
   // Billing
    {
      name: 'Billing',
      type: 'dropDown',
      icon: 'icon-file',
      sub: [
        { icon: 'icon-file', name: 'Billing history', state: '/admin/customerbills', type: 'link' },
        
       
      ]
    },
    //Billing
    //Helpdesk
    {
      name: 'Helpdesk',
      type: 'dropDown',
      icon: 'icon-help-circle',
      sub: [
        { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/admin/helpdesklist', type: 'link' },
              
      ]
    },
    //Helpdesk
    //Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
        { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/admin/reports', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/admin/wingwisereports', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/admin/monthwisereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/admin/customdatereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'SAP Daily Report Export', state: '/admin/dailyreports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Daily export reports', state: '/admin/dailyexportreports', type: 'link' },
              
      ]
    },
    //Reports

    
  ]
  admin1Menu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/assam/assamdashboard', type: 'link' },
      
      ]
    },
  
    //customer
    //master
    // {
    //   name: 'Master',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-zap', name: 'Meter Master', state: '/admin1/metermaster', type: 'link' },
    //     { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/admin1/activeinactivemeters', type: 'link' },
    //     { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/admin1/assignunassignmeters', type: 'link' },
    //   ]
    // },
    //master
    //Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
    //   ]
    // },
    //Tanker Management
   // Billing
    // {
    //   name: 'Billing',
    //   type: 'dropDown',
    //   icon: 'icon-file',
    //   sub: [
    //     { icon: 'icon-file', name: 'Billing history', state: '/admin1/customerbills', type: 'link' },
        
       
    //   ]
    // },
    //Billing
    //Helpdesk
    // {
    //   name: 'Helpdesk',
    //   type: 'dropDown',
    //   icon: 'icon-help-circle',
    //   sub: [
    //     { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/admin1/helpdesklist', type: 'link' },
              
    //   ]
    // },
    //Helpdesk
    //Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
        // { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/admin1/reports', type: 'link' },
        // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/admin1/wingwisereports', type: 'link' },
        // { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/admin1/monthwisereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/admin1/customdatereports', type: 'link' },
        //  { icon: 'icon-chart-bar', name: 'SAP Daily Report Export', state: '/admin1/dailyreports', type: 'link' },
        //  { icon: 'icon-chart-bar', name: 'Daily export reports', state: '/admin1/dailyexportreports', type: 'link' },
              
      ]
    },
    //Reports

    
  ]

  //new sidemenu
  newMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/new/dashboard', type: 'link' },
      
      ]
    },
   // customer
    {
      name: 'Customer',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Customer List', state: '/zenpadmin/zencustomerlist', type: 'link' },
       
      ]
    },
  //  customer
   // master
    {
      name: 'Master',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-zap', name: 'Meter Master', state: '/new/metermaster', type: 'link' },
        { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/new/activeinactivemeters', type: 'link' },
        { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/new/assignunassignmeters', type: 'link' },
        { icon: 'icon-zap', name: 'Meter Status', state: '/new/meterstatus', type: 'link' },
      ]
    },
   // master
    //Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
    //   ]
    // },
    //Tanker Management
   // Billing
    // {
    //   name: 'Billing',
    //   type: 'dropDown',
    //   icon: 'icon-file',
    //   sub: [
    //     { icon: 'icon-file', name: 'Billing history', state: '/admin/customerbills', type: 'link' },
        
       
    //   ]
    // },
    //Billing
   // Helpdesk
    {
      name: 'Helpdesk',
      type: 'dropDown',
      icon: 'icon-help-circle',
      sub: [
        { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/new/helpdesk', type: 'link' },
              
      ]
    },
   // Helpdesk
    //Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
        { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/new/reports', type: 'link' },
        // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/admin/wingwisereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/new/monthwisereports', type: 'link' },
          { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/new/customdatereports', type: 'link' },
        //  { icon: 'icon-chart-bar', name: 'SAP Daily Report Export', state: '/admin/dailyreports', type: 'link' },
        //  { icon: 'icon-chart-bar', name: 'Daily export reports', state: '/admin/dailyexportreports', type: 'link' },
              
      ]
    },
    //Reports

    
  ]

  //new sidemenu


  // operations 
  operationsMenu: IMenuItem1[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/sadmin/sadmindashboard', type: 'link' },
    
      ]
    },
    {
      name: 'Helpdesk',
      type: 'dropDown',
      icon: 'icon-help-circle',
      sub: [
        { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/sadmin/sadminhelpdesk', type: 'link' },
              
      ]
    },
   
   
     //customer
     {
      name: 'Customer',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Add Customer', state: '/sadmin/sadminaddcustomer', type: 'link' },
      ]
    },
    //customer
     //customer
     {
      name: 'Master',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-layers1', name: 'Active&Inactive', state: '/sadmin/activeinactive', type: 'link' },
      ]
    },
    //Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
        // { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/sadmin/sadminreports', type: 'link' },
        // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/sadmin/sadminwingwisereports', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/sadmin/sadminmonthwisereports', type: 'link' },
       //  { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/sadmin/sadmincustomdatereports', type: 'link' },
              
      ]
    },
    //Reports
    {
      name: 'Billing',
      type: 'dropDown',
      icon: 'icon-file-text2',
      sub: [
        // { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/sadmin/sadminreports', type: 'link' },
        // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/sadmin/sadminwingwisereports', type: 'link' },
        { icon: 'icon-file-text2', name: 'Billing', state: '/sadmin/billing', type: 'link' },
       //  { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/sadmin/sadmincustomdatereports', type: 'link' },
              
      ]
    },
    //Device health check
    {
      name: 'Device Check',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Over Consumption', state: '/sadmin/overconsumption', type: 'link' },
        { icon: 'icon-user', name: 'Deviation Check', state: '/sadmin/deviationcheck', type: 'link' },
      ]
    },
    {
      name: 'Track Here',
      type: 'dropDown',
      icon: 'icon-map1',
      sub: [
        { icon: 'icon-map1', name: 'Track Here', state: '/sadmin/sadmintrackhere', type: 'link' },
        
      ]
    },
    {
      name: 'Restart Status',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Restart status', state: '/sadmin/devicerestartstatus', type: 'link' },
        { icon: 'icon-user', name: 'Ontime status', state: '/sadmin/ontimestatus', type: 'link' },
        
      ]
    },
    //Device health check
    //customer
    //Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
    //   ]
    // },
    //Tanker Management
    //Billing
    // {
    //   name: 'Billing',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Billing history', state: '/padmin/customerbills', type: 'link' },
      
       
    //   ]
    // },
    //Billing
    //Helpdesk
    // {
    //   name: 'Helpdesk',
    //   type: 'dropDown',
    //   icon: 'icon-help-circle',
    //   sub: [
    //     { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/padmin/helpdesklist', type: 'link' },
              
    //   ]
    // },
    //Helpdesk
    //Reports
    // {
    //   name: 'Reports',
    //   type: 'dropDown',
    //   icon: 'icon-chart-bar',
    //   sub: [
    //     { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/padmin/reports', type: 'link' },
    //     { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/padmin/wingwisereports', type: 'link' },
    //     { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/padmin/monthwisereports', type: 'link' },
    //      { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/padmin/customdatereports', type: 'link' },
              
    //   ]
    // },
    //Reports
    // {
    //   name: 'UI kits',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Alerts', state: '/uikits/alerts', type: 'link' },
    //     { icon: 'icon-chevrons-down', name: 'Accordions', state: '/uikits/accordions', type: 'link' },
    //     { icon: 'icon-award', name: 'Badges', state: '/uikits/badges', type: 'link' },
    //     { icon: 'icon-arrow-right-circle', name: 'Buttons', state: '/uikits/buttons', type: 'link' },
    //     { icon: 'icon-copy1', name: 'Cards', state: '/uikits/cards', type: 'link' },
    //     { icon: 'icon-box', name: 'Cards widget', state: '/uikits/cards-widget', type: 'link' },
    //     { icon: 'icon-bar-chart', name: 'Cards metrics', state: '/uikits/cards-metrics', type: 'link' },
    //     { icon: 'icon-shopping-bag', name: 'Cards ecommerce', state: '/uikits/cards-ecommerce', type: 'link' },
    //     { icon: 'icon-square', name: 'Modals', state: '/uikits/modals', type: 'link' },
    //     { icon: 'icon-loader', name: 'Loaders', state: '/uikits/loaders', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Ecommerce',
    //   type: 'dropDown',
    //   icon: 'icon-shopping-bag',
    //   sub: [
    //     { icon: 'icon-package', name: 'Products', state: '/ecommerce/products', type: 'link' },
    //     { icon: 'icon-book-open', name: 'Product Details', state: '/ecommerce/product-details', type: 'link' },
    //     { icon: 'icon-shopping-cart', name: 'Cart', state: '/ecommerce/cart', type: 'link' },
    //     { icon: 'icon-credit-card1', name: 'Checkout', state: '/ecommerce/checkout', type: 'link' }
    //   ]
    // },
    // {
    //   name: 'Forms',
    //   type: 'dropDown',
    //   icon: 'icon-edit-pencil',
    //   sub: [
    //     { icon: 'icon-book-open', name: 'Basic Inputs', state: '/forms/basic', type: 'link' },
    //     { icon: 'icon-bookmark1', name: 'Tag Input', state: '/forms/tag-input', type: 'link' },
    //     { icon: 'icon-edit', name: 'Input Group', state: '/forms/input-group', type: 'link' },
    //     { icon: 'icon-grid', name: 'Input Grids', state: '/forms/input-grids', type: 'link' },
    //     { icon: 'icon-zap', name: 'Actions', state: '/forms/actions', type: 'link' },
    //     { icon: 'icon-magic-wand', name: 'Wizards', state: '/forms/wizard', type: 'link' },
    //     { icon: 'icon-check-square', name: 'Validation', state: '/forms/validation', type: 'link' },
    //     { icon: 'icon-layout', name: 'Layouts', state: '/forms/layouts', type: 'link' },
    //     { icon: 'icon-crop1', name: 'Image Cropper', state: '/forms/img-cropper', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Charts',
    //   type: 'dropDown',
    //   icon: 'icon-chart-bar',
    //   sub: [
    //     { icon: 'icon-activity', name: 'Line Charts', state: '/charts/line', type: 'link' },
    //     { icon: 'icon-chart', name: 'Area Charts', state: '/charts/area', type: 'link' },
    //     { icon: 'icon-bar-chart-2', name: 'Bar Charts', state: '/charts/bar', type: 'link' },
    //     { icon: 'icon-pie-chart1', name: 'Pai & Donut', state: '/charts/pai', type: 'link' },
    //     { icon: 'icon-time', name: 'Timeriver', state: '/charts/timeriver', type: 'link' },
    //     { icon: 'icon-sliders', name: 'Candlestick', state: '/charts/candlestick', type: 'link' },
    //     { icon: 'icon-sun1', name: 'Heatmap', state: '/charts/heatmap', type: 'link' },
    //     { icon: 'icon-load-balancer', name: 'Treemap', state: '/charts/treemap', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Data Tables',
    //   type: 'dropDown',
    //   icon: 'icon-view-column',
    //   sub: [
    //     { icon: 'icon-align-justify', name: 'Basic Tables', state: '/tables/basic', type: 'link' },
    //     { icon: 'icon-list1', name: 'List', state: '/tables/list', type: 'link' },
    //     { icon: 'icon-maximize', name: 'Fullscreen', state: '/tables/full', type: 'link' },
    //     { icon: 'icon-book-open', name: 'Paging', state: '/tables/paging', type: 'link' },
    //     { icon: 'icon-filter1', name: 'Filter', state: '/tables/filter', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Apps',
    //   type: 'dropDown',
    //   icon: 'icon-inbox-download',
    //   sub: [
    //     { icon: 'icon-inbox', name: 'Inbox', state: '/inbox', type: 'link' },
    //     { icon: 'icon-message-square', name: 'Chat', state: '/chat', type: 'link' },
    //     { icon: 'icon-calendar1', name: 'Calendar', state: '/calendar', type: 'link' },
    //   ]
    // },
    // {
    //   name: 'Sessions',
    //   type: 'dropDown',
    //   icon: 'icon-user-solid-circle',
    //   sub: [
    //     { icon: 'icon-log-in', name: 'Sign up', state: '/sessions/signup', type: 'link' },
    //     { icon: 'icon-log-out', name: 'Sign in', state: '/sessions/signin', type: 'link' },
    //     { icon: 'icon-users1', name: 'Forgot', state: '/sessions/forgot', type: 'link' }
    //   ]
    // },
    // {
    //   name: 'Others',
    //   type: 'dropDown',
    //   icon: 'icon-stroke-width',
    //   sub: [
    //     { icon: 'icon-window', name: 'Not found', state: '/others/404', type: 'link' }
    //   ]
    // }
  ]


  //operations
  assamMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/assam/assamdashboard', type: 'link' },
      
      ]
    },
   // customer
    // {
    //   name: 'Customer',
    //   type: 'dropDown',
    //   icon: 'icon-users',
    //   sub: [
    //     { icon: 'icon-user', name: 'Customer List', state: '/zenpadmin/zencustomerlist', type: 'link' },
       
    //   ]
    // },
  //  customer
   // master
    // {
    //   name: 'Master',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-zap', name: 'Meter Master', state: '/new/metermaster', type: 'link' },
    //     { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/new/activeinactivemeters', type: 'link' },
    //     { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/new/assignunassignmeters', type: 'link' },
    //   ]
    // },
   // master
    //Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
    //   ]
    // },
    //Tanker Management
   // Billing
    // {
    //   name: 'Billing',
    //   type: 'dropDown',
    //   icon: 'icon-file',
    //   sub: [
    //     { icon: 'icon-file', name: 'Billing history', state: '/admin/customerbills', type: 'link' },
        
       
    //   ]
    // },
    //Billing
   // Helpdesk
    // {
    //   name: 'Helpdesk',
    //   type: 'dropDown',
    //   icon: 'icon-help-circle',
    //   sub: [
    //     { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/new/helpdesk', type: 'link' },
              
    //   ]
    // },
   // Helpdesk
    //Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
        // { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/new/reports', type: 'link' },
        // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/admin/wingwisereports', type: 'link' },
        //  { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/new/monthwisereports', type: 'link' },
          { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/assam/assamreports', type: 'link' },
          { icon: 'icon-chart-bar', name: 'Daily Reports', state: '/assam/dailyreports', type: 'link' },
          // { icon: 'icon-chart-bar', name: 'Energy Reports', state: '/assam/Energyreports', type: 'link' },
        //  { icon: 'icon-chart-bar', name: 'Daily export reports', state: '/admin/dailyexportreports', type: 'link' },
              
      ]
    },
    //Reports

    
  ]

  //volvo menu
  volvoMenu: IMenuItem1[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [ 
       
        { icon: 'icon-home', name: 'Dashboard', state: '/volvo/volvodashboard', type: 'link' },
        // { icon: 'icon-home', name: 'Water Quality Dashboard', state: '', type: 'link' },
        // { icon: 'icon-home', name: 'Dashboard', state: '', type: 'link' },
    
      ]
    },
    //customer
    {
      name: 'Device',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Device List', state: '/volvo/volvocustomers', type: 'link' },
      ]
    },
   // customer
    //master
    {
      name: 'Master',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-zap', name: 'Meter Master', state: '/volvo/volvometermaster', type: 'link' },
        { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/volvo/volvoactiveinactivemeters', type: 'link' },
        { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/volvo/volvoassignunassignmeters', type: 'link' },
      ]
    },
    //master
   
    //Billing
    // {
    //   name: 'Billing',
    //   type: 'dropDown',
    //   icon: 'icon-file',
    //   sub: [
    //     { icon: 'icon-file', name: 'Customer Bills', state: '/zenpadmin/zencustomerbills', type: 'link' },
    //     { icon: 'icon-file', name: 'Meter Rate Card', state: '/zenpadmin/zenmeterratecard', type: 'link' },
      
       
    //   ]
    // },
    //Billing
    //Helpdesk
    // {
    //   name: 'Helpdesk',
    //   type: 'dropDown',
    //   icon: 'icon-help-circle',
    //   sub: [
    //     { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/zenpadmin/zenhelpdesk', type: 'link' },
              
    //   ]
    // },
    //Helpdesk
   // Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
        { icon: 'icon-chart-bar', name: 'Location wise reports', state: '/volvo/volvoreports', type: 'link' },
        // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/volvo/volvowingwisereports', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/volvo/volvomonthwisereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/volvo/volvocustomdatereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Date-time Reports', state: '/volvo/customdatetimereports', type: 'link' }
              
      ]
    },
    // {
    //   name: 'Water Quality',
    //   type: 'dropDown',
    //   icon: 'icon-droplet1',
    //   sub: [
    //     { icon: 'icon-home', name: 'Dashboard', state: '/zenpadmin/waterqualitydashboard', type: 'link' },
    //     { icon: 'icon-chart-bar', name: 'Daily Reports', state: '/zenpadmin/waterqualityreports', type: 'link' },
    //     { icon: 'icon-chart-bar', name: 'Monthly Reports', state: '/zenpadmin/waterqualitymonthlyreports', type: 'link' },
      
              
    //   ]
    // },
   ]
   
   
  bharatiyaMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
        
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/bharatiya/dashboard', type: 'link' },
       
      ]
    },
    //customer
    {
      name: 'Customer',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Customer List', state: '/bharatiya/customers', type: 'link' },
      ]
    },
    //customer
    //master
    {
      name: 'Master',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-zap', name: 'Meter Master', state: '/bharatiya/metermaster', type: 'link' },
        { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/bharatiya/activeinactive', type: 'link' },
       // { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/padmin/assignunassignmeters', type: 'link' },
       // { icon: 'icon-zap', name: 'Device Status', state: '/padmin/devicestatus', type: 'link' },
      ]
    },
    //master
    //Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
    //   ]
    // },
    //Tanker Management
    //Billing
    // {
    //   name: 'Billing',
    //   type: 'dropDown',
    //   icon: 'icon-file',
    //   sub: [
    //     { icon: 'icon-file', name: 'Billing history', state: '/padmin/customerbills', type: 'link' },
        
       
    //   ]
    // },
    //Billing
    //Helpdesk
    // {
    //   name: 'Helpdesk',
    //   type: 'dropDown',
    //   icon: 'icon-help-circle',
    //   sub: [
    //     { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/padmin/helpdesklist', type: 'link' },
              
    //   ]
    // },
    //Helpdesk
    //Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
       // { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/padmin/reports', type: 'link' },
       // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/padmin/wingwisereports', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/bharatiya/monthwisereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/bharatiya/customdatereports', type: 'link' },
       //  { icon: 'icon-chart-bar', name: 'SAP Daily Report Export', state: '/padmin/dailyreports', type: 'link' },
       //  { icon: 'icon-chart-bar', name: 'Daily export reports', state: '/padmin/dailyexportreports', type: 'link' },
              
      ]
    },
    //Reports
  
  ]
  //

//adityabirla
  adityabirla: IMenuItem1[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
        
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/adityabirla/maindash', type: 'link' },
       
      ]
    },
    //customer
    {
      name: 'Customer',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Device List', state: '/adityabirla/customerlist', type: 'link' },
      ]
    },
    //customer
    //master
    {
      name: 'Master',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-zap', name: 'Meter Master', state: '/adityabirla/metermaster', type: 'link' },
        // { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/adityabirla/activeinactivemeters', type: 'link' },
       // { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/padmin/assignunassignmeters', type: 'link' },
       // { icon: 'icon-zap', name: 'Device Status', state: '/padmin/devicestatus', type: 'link' },
      ]
    },
    //master
    //Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
    //   ]
    // },
    //Tanker Management
    //Billing
    // {
    //   name: 'Billing',
    //   type: 'dropDown',
    //   icon: 'icon-file',
    //   sub: [
    //     { icon: 'icon-file', name: 'Billing history', state: '/padmin/customerbills', type: 'link' },
        
       
    //   ]
    // },
    //Billing
    //Helpdesk
    // {
    //   name: 'Helpdesk',
    //   type: 'dropDown',
    //   icon: 'icon-help-circle',
    //   sub: [
    //     { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/padmin/helpdesklist', type: 'link' },
              
    //   ]
    // },
    //Helpdesk
    //Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
       // { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/padmin/reports', type: 'link' },
       // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/padmin/wingwisereports', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/adityabirla/monthwisereports', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/adityabirla/customdatereports', type: 'link' },
       //  { icon: 'icon-chart-bar', name: 'SAP Daily Report Export', state: '/padmin/dailyreports', type: 'link' },
       //  { icon: 'icon-chart-bar', name: 'Daily export reports', state: '/padmin/dailyexportreports', type: 'link' },
       { icon: 'icon-chart-bar', name: 'Custom Date time reports', state: '/adityabirla/customdatetimereports', type: 'link' },
              
      ]
    },
    //Reports
  
  ]

   //

//nikoohomes
nikoohomes: IMenuItem[] = [
  {
    name: 'Dashboard',
    type: 'dropDown',
    icon: 'icon-home',
    sub: [
      
      { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/nikoohomes/nikoodashboard', type: 'link' },
     
    ]
  },
  //customer
  {
    name: 'Customer',
    type: 'dropDown',
    icon: 'icon-users',
    sub: [
      { icon: 'icon-user', name: 'Customer List', state: '/nikoohomes/customerlist', type: 'link' },
    ]
  },
  //customer
  //master
  // {
  //   name: 'Master',
  //   type: 'dropDown',
  //   icon: 'icon-layers1',
  //   sub: [
  //     // { icon: 'icon-zap', name: 'Meter Master', state: '/bharatiya/metermaster', type: 'link' },
  //     // { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/bharatiya/activeinactive', type: 'link' },
  //    // { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/padmin/assignunassignmeters', type: 'link' },
  //    // { icon: 'icon-zap', name: 'Device Status', state: '/padmin/devicestatus', type: 'link' },
  //    { icon: 'icon-zap', name: 'Meter Status', state: '/nikoohomes/nikoometerstatus', type: 'link' },
  //   ]
  // },
  //master 
  //Tanker Management
  // {
  //   name: 'Tanker Management',
  //   type: 'dropDown',
  //   icon: 'icon-layers1',
  //   sub: [
  //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
  //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
     
  //   ]
  // },
  //Tanker Management
  //Billing
  // {
  //   name: 'Billing',
  //   type: 'dropDown',
  //   icon: 'icon-file',
  //   sub: [
  //     { icon: 'icon-file', name: 'Billing history', state: '/padmin/customerbills', type: 'link' },
      
     
  //   ]
  // },
  //Billing
  //Helpdesk
  // {
  //   name: 'Helpdesk',
  //   type: 'dropDown',
  //   icon: 'icon-help-circle',
  //   sub: [
  //     { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/padmin/helpdesklist', type: 'link' },
            
  //   ] 
  // }, 
  //Helpdesk
  //Reports
  {
    name: 'Reports', 
    type: 'dropDown',
    icon: 'icon-chart-bar',
    sub: [
     // { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/padmin/reports', type: 'link' },
     // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/padmin/wingwisereports', type: 'link' },
      { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/nikoohomes/monthwisereports', type: 'link' },
       { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/nikoohomes/customdatereports', type: 'link' },
      // { icon: 'icon-chart-bar', name: 'Full Days reports ', state: '/nikoohomes/customfulldayreport', type: 'link' },
      { icon: 'icon-chart-bar', name: 'Custom 24Hrs Days reports', state: '/nikoohomes/customdatetimereports', type: 'link' },
            
    ]
  },
    //Helpdesk
    {
      name: 'Helpdesk',
      type: 'dropDown',
      icon: 'icon-help-circle',
      sub: [
        { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/new/helpdesk', type: 'link' },
              
      ]
    },

  

]

//ahadopus
  ahadopus: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/ahadopus/opusdashboard', type: 'link' },
      
      ]
    },
   // customer
    {
      name: 'Customer',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Customer List', state: '/ahadopus/opuscustomerlist', type: 'link' },
       
      ]
    },
  //  customer
   // master
    {
      name: 'Master',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-zap', name: 'Meter Master', state: '/ahadopus/opusmetermaster', type: 'link' },
        { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/ahadopus/opusactiveinactivemeters', type: 'link' },
        { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/ahadopus/opusassignedandunassignedmeters', type: 'link' },
        { icon: 'icon-zap', name: 'Meter Status', state: '/ahadopus/opusmeterstatus', type: 'link' },
      ]
    },
   // master
    //Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
    //   ]
    // },
    //Tanker Management
   // Billing
    // {
    //   name: 'Billing',
    //   type: 'dropDown',
    //   icon: 'icon-file',
    //   sub: [
    //     { icon: 'icon-file', name: 'Billing history', state: '/admin/customerbills', type: 'link' },
        
       
    //   ]
    // },
    //Billing
   // Helpdesk
    {
      name: 'Helpdesk',
      type: 'dropDown',
      icon: 'icon-help-circle',
      sub: [
        { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/new/helpdesk', type: 'link' },
              
      ]
    },
   // Helpdesk
    //Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
        { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/ahadopus/opuscustomerwisereports', type: 'link' },
        // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/admin/wingwisereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/ahadopus/opusmonthwisereports', type: 'link' },
          { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/ahadopus/opuscustomdatereports', type: 'link' },
        //  { icon: 'icon-chart-bar', name: 'SAP Daily Report Export', state: '/admin/dailyreports', type: 'link' },
        //  { icon: 'icon-chart-bar', name: 'Daily export reports', state: '/admin/dailyexportreports', type: 'link' },
              
      ]
    },
    //Reports

    
  ]
  //Elcita
  // elcitaMenu: IMenuItem[] = [
  //   {
  //     name: 'Dashboard',
  //     type: 'dropDown',
  //     icon: 'icon-home',
  //     sub: [ 
       
  //       { icon: 'icon-home', name: 'Dashboard', state: '/elcita/elcitadashboard', type: 'link' },
  //       // { icon: 'icon-home', name: 'Water Quality Dashboard', state: '', type: 'link' },
  //       // { icon: 'icon-home', name: 'Dashboard', state: '', type: 'link' },
    
  //     ]
  //   },
  //   //customer
  //   {
  //     name: 'Device',
  //     type: 'dropDown',
  //     icon: 'icon-users',
  //     sub: [
  //       { icon: 'icon-user', name: 'Device List', state: '/elcita/elcitacustomers', type: 'link' },
  //     ]
  //   },
  //  // customer
  //   //master
  //   {
  //     name: 'Master',
  //     type: 'dropDown',
  //     icon: 'icon-layers1',
  //     sub: [
  //       { icon: 'icon-zap', name: 'Meter Master', state: '/elcita/elcitametermaster', type: 'link' },
  //       { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/elcita/elcitaactiveinactivemeters', type: 'link' },
  //       { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/elcita/elcitaassignunassignmeters', type: 'link' },
  //       // { icon: 'icon-zap', name: 'Monthwise Active/InActive ', state: '/volvo/monthwiseactiveinactive', type: 'link' },
  //     ]
  //   },
  //   {
  //     name: 'Reports',
  //     type: 'dropDown',
  //     icon: 'icon-chart-bar',
  //     sub: [
  //       { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/elcita/elcitareports', type: 'link' },
  //       // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/volvo/volvowingwisereports', type: 'link' },
  //       { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/elcita/elcitamonthwisereports', type: 'link' },
  //        { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/elcita/elcitacustomdatereports', type: 'link' },
              
  //     ]
  //   },
  // ]
  //Namdhari
  namdhariMenu: IMenuItem1[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
        
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/Namdharis/namdharidashboard', type: 'link' },
       
      ]
    },
    //customer
    {
      name: 'Device',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Device List', state: '/Namdharis/customerlist', type: 'link' },
      ]
    },
    //customer
    //master
    //{
      //name: 'Master',
      //type: 'dropDown',
      //icon: 'icon-layers1',
      //sub: [
        // { icon: 'icon-zap', name: 'Meter Master', state: '/bharatiya/metermaster', type: 'link' },
        // { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/bharatiya/activeinactive', type: 'link' },
       // { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/padmin/assignunassignmeters', type: 'link' },
       // { icon: 'icon-zap', name: 'Device Status', state: '/padmin/devicestatus', type: 'link' },
       //{ icon: 'icon-zap', name: 'Meter Status', state: '/Namdharis/namdharimeterstatus', type: 'link' },
      //]
    //},
    //master 
    //Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
    //   ]
    // },
    //Tanker Management
    //Billing
    // {
    //   name: 'Billing',
    //   type: 'dropDown',
    //   icon: 'icon-file',
    //   sub: [
    //     { icon: 'icon-file', name: 'Billing history', state: '/padmin/customerbills', type: 'link' },
        
       
    //   ]
    // },
    //Billing
    //Helpdesk
    // {
    //   name: 'Helpdesk',
    //   type: 'dropDown',
    //   icon: 'icon-help-circle',
    //   sub: [
    //     { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/padmin/helpdesklist', type: 'link' },
              
    //   ] 
    // }, 
    //Helpdesk
    //Reports
    {
      name: 'Reports', 
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
       // { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/padmin/reports', type: 'link' },
       // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/padmin/wingwisereports', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/Namdharis/monthwisereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/Namdharis/customdatereports', type: 'link' },
        // { icon: 'icon-chart-bar', name: 'Full Days reports ', state: '/nikoohomes/customfulldayreport', type: 'link' },
        //{ icon: 'icon-chart-bar', name: 'Custom 24Hrs Days reports', state: '/Namdharis/customdatetimereports', type: 'link' },
              
      ]
    },
      //Helpdesk
      {
        name: 'Helpdesk',
        type: 'dropDown',
        icon: 'icon-help-circle',
        sub: [
          { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/Namdharis/helpdesk', type: 'link' },
                
        ]
      },
  
    
  
  ]
  nagalandMenu: IMenuItem1[] = [
    {
      name: 'Home',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
        
        { icon: 'icon-bar-chart-2', name: 'Home', state: '/nagaland/nagalanddashboard', type: 'link' },
       
      ]
    },
    {
      name: 'Kezobasa',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/nagaland/kezobasadashboard', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Monthly Report', state: '/nagaland/kezobasareports', type: 'link' },
       
      ]
    },
    {
      name: 'Kigwa Town',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/nagaland/kigwatowndashboard', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Monthly Report', state: '/nagaland/kigwatownreports', type: 'link' },
       
      ]
    },
    {
      name: 'Ngwalwa',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/nagaland/ngwalwadashboard', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Monthly Report', state: '/nagaland/ngwalwareports', type: 'link' },
       
      ]
    },
    {
      name: 'Newland',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/nagaland/newlanddashboard', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Monthly Report', state: '/nagaland/newlandreports', type: 'link' },
       
      ]
    },
  ]
  //BM_Splendor
  bmsplendorMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/bmsplendor/dashboard', type: 'link' },
      
      ]
    },
   // customer
    {
      name: 'Customer',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Customer List', state: '/zenpadmin/zencustomerlist', type: 'link' },
       
      ]
    },
  //  customer
   // master
    {
      name: 'Master',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-zap', name: 'Meter Master', state: '/bmsplendor/metermaster', type: 'link' },
        { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/bmsplendor/activeinactivemeters', type: 'link' },
        { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/bmsplendor/assignunassignmeters', type: 'link' },
        { icon: 'icon-zap', name: 'Meter Status', state: '/bmsplendor/meterstatus', type: 'link' },
      ]
    },
   // master
    //Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
    //   ]
    // },
    //Tanker Management
   // Billing
    // {
    //   name: 'Billing',
    //   type: 'dropDown',
    //   icon: 'icon-file',
    //   sub: [
    //     { icon: 'icon-file', name: 'Billing history', state: '/admin/customerbills', type: 'link' },
        
       
    //   ]
    // },
    //Billing
   // Helpdesk
    {
      name: 'Helpdesk',
      type: 'dropDown',
      icon: 'icon-help-circle',
      sub: [
        { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/bmsplendor/helpdesk', type: 'link' },
              
      ]
    },
   // Helpdesk
    //Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
        { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/bmsplendor/reports', type: 'link' },
        // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/admin/wingwisereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/bmsplendor/monthwisereports', type: 'link' },
          { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/bmsplendor/customdatereports', type: 'link' },
        //  { icon: 'icon-chart-bar', name: 'SAP Daily Report Export', state: '/admin/dailyreports', type: 'link' },
        //  { icon: 'icon-chart-bar', name: 'Daily export reports', state: '/admin/dailyexportreports', type: 'link' },
              
      ]
    },
    //Reports

    
  ]



  ahpa01Menu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/ahpa01/ahpa01dashboard', type: 'link' },
      
      ]
    },
  
    
  ]

  ahpa02Menu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/ahpa01/ahpa01dashboard', type: 'link' },
      
      ]
    },
  
    
  ]

  ahpa03Menu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/ahpa01/ahpa01dashboard', type: 'link' },
      
      ]
    },
  
    
  ]

  ahpa04Menu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/ahpa01/ahpa01dashboard', type: 'link' },
      
      ]
    },
  
    
  ]

  ahpa05Menu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/ahpa01/ahpa01dashboard', type: 'link' },
      
      ]
    },
  
    
  ]



  //SPT
  /*sptMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/spt/dashboard', type: 'link' },
      
      ]
    },
   // customer
    {
      name: 'Customer',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Customer List', state: '/zenpadmin/zencustomerlist', type: 'link' },
       
      ]
    },
  //  customer
   // master
    {
      name: 'Master',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-zap', name: 'Meter Master', state: '/spt/metermaster', type: 'link' },
        { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/spt/activeinactivemeters', type: 'link' },
        { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/spt/assignunassignmeters', type: 'link' },
        { icon: 'icon-zap', name: 'Meter Status', state: '/spt/meterstatus', type: 'link' },
      ]
    },
   // master
    //Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
    //   ]
    // },
    //Tanker Management
   // Billing
    // {
    //   name: 'Billing',
    //   type: 'dropDown',
    //   icon: 'icon-file',
    //   sub: [
    //     { icon: 'icon-file', name: 'Billing history', state: '/admin/customerbills', type: 'link' },
        
       
    //   ]
    // },
    //Billing
   // Helpdesk
    {
      name: 'Helpdesk',
      type: 'dropDown',
      icon: 'icon-help-circle',
      sub: [
        { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/spt/helpdesk', type: 'link' },
              
      ]
    },
   // Helpdesk
    //Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
        { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/spt/reports', type: 'link' },
        //{ icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/admin/wingwisereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/spt/monthwisereports', type: 'link' },
          { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/spt/customdatereports', type: 'link' },
        //  { icon: 'icon-chart-bar', name: 'SAP Daily Report Export', state: '/admin/dailyreports', type: 'link' },
        //  { icon: 'icon-chart-bar', name: 'Daily export reports', state: '/admin/dailyexportreports', type: 'link' },
              
      ]
    },
    //Reports

    
  ]*/
  //Ahad_Excelecia
  // excellentiaMenu: IMenuItem[] = [
  //   {
  //     name: 'Dashboard',
  //     type: 'dropDown',
  //     icon: 'icon-home',
  //     sub: [
       
  //       { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/excellentia/dashboard', type: 'link' },
      
  //     ]
  //   },
  //  // customer
  //   {
  //     name: 'Customer',
  //     type: 'dropDown',
  //     icon: 'icon-users',
  //     sub: [
  //       { icon: 'icon-user', name: 'Customer List', state: '/zenpadmin/zencustomerlist', type: 'link' },
       
  //     ]
  //   },
  // //  customer
  //  // master
  //   {
  //     name: 'Master',
  //     type: 'dropDown',
  //     icon: 'icon-layers1',
  //     sub: [
  //       { icon: 'icon-zap', name: 'Meter Master', state: '/excellentia/metermaster', type: 'link' },
  //       { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/excellentia/activeinactivemeters', type: 'link' },
  //       { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/excellentia/assignunassignmeters', type: 'link' },
  //       { icon: 'icon-zap', name: 'Meter Status', state: '/excellentia/meterstatus', type: 'link' },
  //     ]
  //   },
  //  // master
  //   //Tanker Management
  //   // {
  //   //   name: 'Tanker Management',
  //   //   type: 'dropDown',
  //   //   icon: 'icon-layers1',
  //   //   sub: [
  //   //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
  //   //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
  //   //   ]
  //   // },
  //   //Tanker Management
  //  // Billing
  //   // {
  //   //   name: 'Billing',
  //   //   type: 'dropDown',
  //   //   icon: 'icon-file',
  //   //   sub: [
  //   //     { icon: 'icon-file', name: 'Billing history', state: '/admin/customerbills', type: 'link' },
        
       
  //   //   ]
  //   // },
  //   //Billing
  //  // Helpdesk
  //   {
  //     name: 'Helpdesk',
  //     type: 'dropDown',
  //     icon: 'icon-help-circle',
  //     sub: [
  //       { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/excellentia/helpdesk', type: 'link' },
              
  //     ]
  //   },
  //  // Helpdesk
  //   //Reports
  //   {
  //     name: 'Reports',
  //     type: 'dropDown',
  //     icon: 'icon-chart-bar',
  //     sub: [
  //       { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/excellentia/reports', type: 'link' },
  //       // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/admin/wingwisereports', type: 'link' },
  //        { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/excellentia/monthwisereports', type: 'link' },
  //         { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/excellentia/customdatereports', type: 'link' },
  //       //  { icon: 'icon-chart-bar', name: 'SAP Daily Report Export', state: '/admin/dailyreports', type: 'link' },
  //       //  { icon: 'icon-chart-bar', name: 'Daily export reports', state: '/admin/dailyexportreports', type: 'link' },
              
  //     ]
  //   },
  //   //Reports

    
  // ]
  gujaratMenu: IMenuItem[] = [
    {
      name: 'Home',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
        
        { icon: 'icon-bar-chart-2', name: 'Home', state: '/gujarat/gujaratdashboard', type: 'link' },
       
      ]
    },
    {
      name: 'Borla', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-home', name: 'Borla Dashboard', state: '/gujarat/borladashboard', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Borla Monthlyreport', state: '/gujarat/borlamonthwisereport', type: 'link' },
        
              
      ]
    },
    {
      name: 'Mithiivirdi', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-home', name: 'Mithiivirdi Dashboard', state: '/gujarat/mithiivirdidashboard', type: 'link' },
        { icon: 'icon-chart-bar', name: 'Mithiivirdi Monthlyreport', state: '/gujarat/mithiivirdimonthwisereport', type: 'link' },
       
              
      ]
    },
    {
      name: 'Motaringariyala', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-home', name: 'Motaringariyala Dashboard', state: '/gujarat/motaringariyaladashboard', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Motaringariyala Monthlyreport', state: '/gujarat/motaringariyalamonthwisereport', type: 'link' },
        
              
      ]
    },
    {
      name: 'Fatchriya', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-home', name: 'Fatchriya Dashboard', state: '/gujarat/fatchriyadashboard', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Fatchriya Monthlyreport', state: '/gujarat/fatchriyamonthwisereport', type: 'link' },
        // { icon: 'icon-chart-bar', name: 'Full Days reports ', state: '/nikoohomes/customfulldayreport', type: 'link' },
        //{ icon: 'icon-chart-bar', name: 'Custom 24Hrs Days reports', state: '/Namdharis/customdatetimereports', type: 'link' },
              
      ]
    },
    {
      name: 'Dharabandar', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-home', name: 'Dharabandar Dashboard', state: '/gujarat/dharabandardashboard', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Dharabandar Monthlyreport', state: '/gujarat/dharabandarmonthwisereport', type: 'link' },
      ]
    },
    {
      name: 'Bhungar', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-home', name: 'Bhungar Dashboard', state: '/gujarat/bhungardashboard', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Bhungar Monthlyreport', state: '/gujarat/bhungarmonthwisereport', type: 'link' },
      ]
    },
    {
      name: 'Chhatadiya', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-home', name: 'Chhatadiya Dashboard', state: '/gujarat/chhatadiyadashboard', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Chhatadiya Monthlyreport', state: '/gujarat/chhatadiyamonthwisereport', type: 'link' },
      ]
    },
  
    
  
  ]






  eadminaddMenu: IMenuItem[] = [
    {
      name: 'Customer',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/eadminaddcustomer/eadmindashboard', type: 'link' },
        { icon: 'icon-user', name: 'Add Customer', state: '/eadminaddcustomer/eadminaddcustomer', type: 'link' },
      
      ]
    },
    
    
  ]



  












  evidenceMenu: IMenuItem2[]=[
    {
      name: 'Home',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
        
       
       { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/evidence/evidencedashboard', type: 'link' },
       { icon: 'icon-chart-bar', name: 'EVIDENCE Monthlyreport', state: '/evidence/evidencereports', type: 'link' },        
       
      ]
    },
    {
      name: 'MADHYA PRADESH',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
    {
      name: 'MVS',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
      {
          name: 'SINGPUR', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'SINGPUR Dashboard', state: '/evidence/singpurdashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'SINGPUR Monthlyreport', state: '/evidence/singpurreports', type: 'link' },  
            { icon: 'icon-zap', name: 'SINGPUR CustomDateReport', state: '/evidence/singpurcustomdatereports', type: 'link' },      
          ]
        },
        {
          name: 'AMEERGANJ', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'AMEERGANJ Dashboard', state: '/evidence/ameerganjdashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'AMEERGANJ Monthlyreport', state: '/evidence/ameerganjreports', type: 'link' },        
            { icon: 'icon-zap', name: 'AMEERGANJ CustomDateReport', state: '/evidence/ameerganjcustomdatereports', type: 'link' },
          ]
        },
               
      ]
    },

  {
      name: 'INTERMEDIATARY',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        {
          name: 'KANKARIYA', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'KANKARIYA Dashboard', state: '/evidence/kankariyadashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'KANKARIYA Monthlyreport', state: '/evidence/kankariyareports', type: 'link' },   
            { icon: 'icon-zap', name: 'KANKARIYA CustomDateReport', state: '/evidence/kankariyacustomdatereports', type: 'link' },           
          ]
        },]},


    {
      name: 'SVS', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        {
          name: 'KANASIYA', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'KANASIYA Dashboard', state: '/evidence/kanasiyadashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'KANASIYA Monthlyreport', state: '/evidence/kanasiyareports', type: 'link' },    
            { icon: 'icon-zap', name: 'KANASIYA CustomDateReport', state: '/evidence/kanasiyacustomdatereports', type: 'link' },          
          ]
        },
        {
          name: 'BARKHEDA', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'BARKHEDA Dashboard', state: '/evidence/barkhedadashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'BARKHEDA Monthlyreport', state: '/evidence/barkhedareports', type: 'link' },  
            { icon: 'icon-zap', name: 'BARKHEDA CustomDateReport', state: '/evidence/barkhedacustomdatereports', type: 'link' },      
          ]
        },
        {
          name: 'PACHAMA', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'PACHAMA Dashboard', state: '/evidence/pachamadashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'PACHAMA Monthlyreport', state: '/evidence/pachamareports', type: 'link' },
            { icon: 'icon-zap', name: 'PACHAMA CustomDateReport', state: '/evidence/pachamacustomdatereports', type: 'link' },       
                 
          ]
        },
        {
          name: 'BARKHEDI', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'BARKHEDI Dashboard', state: '/evidence/barkhedidashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'BARKHEDI Monthlyreport', state: '/evidence/barkhedireports', type: 'link' },  
            { icon: 'icon-zap', name: 'BARKHEDI CustomDateReport', state: '/evidence/barkhedicustomdatereports', type: 'link' },      
          ]
        },
        {
          name: 'SHYAMPUR', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'SHYAMPUR Dashboard', state: '/evidence/shyampurdashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'SHYAMPUR Monthlyreport', state: '/evidence/shyampurreports', type: 'link' },  
            { icon: 'icon-zap', name: 'SHYAMPUR CustomDateReport', state: '/evidence/shyampurcustomdatereports', type: 'link' },            
          ]
        },
        {
          name: 'LANCHOR', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'LANCHOR Dashboard', state: '/evidence/lanchordashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'LANCHOR Monthlyreport', state: '/evidence/lanchorreports', type: 'link' },  
            { icon: 'icon-zap', name: 'LANCHOR CustomDateReport', state: '/evidence/lanchorcustomdatereports', type: 'link' },            
          ]
        },
      ]
    },
  ]
},



{
  name: 'ANDHRA PRADESH',
  type: 'dropDown',
  icon: 'icon-layers1',
  sub: [
{
  name: 'MVS',
  type: 'dropDown',
  icon: 'icon-layers1',
  sub: [
  {
      name: 'ELAPROLU', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-home', name: 'ELAPROLU Dashboard', state: '/evidence/elaproludashboard', type: 'link' },
        { icon: 'icon-chart-bar', name: 'ELAPROLU Monthlyreport', state: '/evidence/elaprolureports', type: 'link' },    
        { icon: 'icon-zap', name: 'ELAPROLU CustomDateReport', state: '/evidence/elaprolucustomdatereports', type: 'link' },                
      ]
    },
    {
      name: 'SUNNAMPADU', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-home', name: 'SUNNAMPADU Dashboard', state: '/evidence/sunnampadudashboard', type: 'link' },
        { icon: 'icon-chart-bar', name: 'SUNNAMPADU Monthlyreport', state: '/evidence/sunnampadureports', type: 'link' },  
        { icon: 'icon-zap', name: 'SUNNAMPADU CustomDateReport', state: '/evidence/sunnampaducustomdatereports', type: 'link' },                      
      ]
    },
    {
      name: 'RANGAPURAM', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-home', name: 'RANGAPURAM Dashboard', state: '/evidence/rangapuramdashboard', type: 'link' },
        { icon: 'icon-chart-bar', name: 'RANGAPURAM Monthlyreport', state: '/evidence/rangapuramreports', type: 'link' }, 
        { icon: 'icon-zap', name: 'RANGAPURAM CustomDateReport', state: '/evidence/rangapuramcustomdatereports', type: 'link' },                       
      ]
    },
           
  ]
},




{
  name: 'SVS', 
  type: 'dropDown',
  icon: 'icon-layers1',
  sub: [
    {
      name: 'KOTIKALAPUDI', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-home', name: 'KOTIKALAPUDI Dashboard', state: '/evidence/kotikalapudidashboard', type: 'link' },
        { icon: 'icon-chart-bar', name: 'KOTIKALAPUDI Monthlyreport', state: '/evidence/kotikalapudireports', type: 'link' },   
        { icon: 'icon-zap', name: 'KOTIKALAPUDI CustomDateReport', state: '/evidence/kotikalapudicustomdatereports', type: 'link' },                 
      ]
    },
    {
      name: 'GOPANNAPALEM', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-home', name: 'GOPANNAPALEM Dashboard', state: '/evidence/gopannapalemdashboard', type: 'link' },
        { icon: 'icon-chart-bar', name: 'GOPANNAPALEM Monthlyreport', state: '/evidence/gopannapalemreports', type: 'link' },   
        { icon: 'icon-zap', name: 'GOPANNAPALEM CustomDateReport', state: '/evidence/gopannapalemcustomdatereports', type: 'link' },                 
      ]
    },
    {
      name: 'MADEPALLI', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-home', name: 'MADEPALLI Dashboard', state: '/evidence/madepallidashboard', type: 'link' },
        { icon: 'icon-chart-bar', name: 'MADEPALLI Monthlyreport', state: '/evidence/madepallireports', type: 'link' }, 
        { icon: 'icon-zap', name: 'MADEPALLI CustomDateReport', state: '/evidence/madepallicustomdatereports', type: 'link' },                   
      ]
    },
  ]
},
]
},
]


pondugalaMenu: IMenuItem2[]=[
  {
    name: 'Home',
    type: 'dropDown',
    icon: 'icon-home',
    sub: [
      
     
     { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/pondugala/pondugaladashboard', type: 'link' },
   
     
    ]
  },
 
    ]


    
tripuraMenu: IMenuItem2[]=[
  {
    name: 'Home',
    type: 'dropDown',
    icon: 'icon-home',
    sub: [
      
      { icon: 'icon-zap', name: 'NABAGRAM', state: '/tripura/nabagramdashboard', type: 'link' },
     { icon: 'icon-zap', name: 'AMBASSA', state: '/tripura/tripuradashboard', type: 'link' },
    
     
     
    ]
  },
 ]
 jindalMenu: IMenuItem[] = [
  {
    name: 'Dashboard',
    type: 'dropDown',
    icon: 'icon-home',
    sub: [
      
      { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/jindal/jindaldashboard', type: 'link' },
     
    ]
  },
  //customer
  // {
  //   name: 'Customer',
  //   type: 'dropDown',
  //   icon: 'icon-users',
  //   sub: [
  //     { icon: 'icon-user', name: 'Customer List', state: '/jindal/customerlist', type: 'link' },
  //   ]
  // },
  //customer
  //master
  // {
  //   name: 'Master',
  //   type: 'dropDown',
  //   icon: 'icon-layers1',
  //   sub: [
  //     // { icon: 'icon-zap', name: 'Meter Master', state: '/bharatiya/metermaster', type: 'link' },
  //     // { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/bharatiya/activeinactive', type: 'link' },
  //    // { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/padmin/assignunassignmeters', type: 'link' },
  //    // { icon: 'icon-zap', name: 'Device Status', state: '/padmin/devicestatus', type: 'link' },
  //    { icon: 'icon-zap', name: 'Meter Status', state: '/nikoohomes/nikoometerstatus', type: 'link' },
  //   ]
  // },
  //master 
  //Tanker Management
  // {
  //   name: 'Tanker Management',
  //   type: 'dropDown',
  //   icon: 'icon-layers1',
  //   sub: [
  //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
  //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
     
  //   ]
  // },
  //Tanker Management
  //Billing
  // {
  //   name: 'Billing',
  //   type: 'dropDown',
  //   icon: 'icon-file',
  //   sub: [
  //     { icon: 'icon-file', name: 'Billing history', state: '/padmin/customerbills', type: 'link' },
      
     
  //   ]
  // },
  //Billing
  //Helpdesk
  // {
  //   name: 'Helpdesk',
  //   type: 'dropDown',
  //   icon: 'icon-help-circle',
  //   sub: [
  //     { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/padmin/helpdesklist', type: 'link' },
            
  //   ] 
  // }, 
  //Helpdesk
  //Reports
  {
    name: 'Reports', 
    type: 'dropDown',
    icon: 'icon-chart-bar',
    sub: [
     // { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/padmin/reports', type: 'link' },
     // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/padmin/wingwisereports', type: 'link' },
      { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/jindal/monthwisereports', type: 'link' },
      //  { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/jindal/customdatereports', type: 'link' },
      // { icon: 'icon-chart-bar', name: 'Full Days reports ', state: '/nikoohomes/customfulldayreport', type: 'link' },
      // { icon: 'icon-chart-bar', name: 'Custom 24Hrs Days reports', state: '/jindal/customdatetimereports', type: 'link' },
            
    ]
  },
    //Helpdesk
    // {
    //   name: 'Helpdesk',
    //   type: 'dropDown',
    //   icon: 'icon-help-circle',
    //   sub: [
    //     { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/new/helpdesk', type: 'link' },
              
    //   ]
    // },

  

]







chirugoraMenu: IMenuItem[] = [
  {
    name: 'Dashboard',
    type: 'dropDown',
    icon: 'icon-home',
    sub: [
      
      { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/chirugora/chirugoradashboard', type: 'link' },
      { icon: 'icon-chart-bar', name: 'Chirugora Monthlyreport', state: '/chirugora/chirugorareports', type: 'link' },        
    ]
  },
  

  

  

]










ranchiMenu: IMenuItem2[]=[
  {
    name: 'Home',
    type: 'dropDown',
    icon: 'icon-home',
    sub: [
      
     
     { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/ranchi/ranchidashboard', type: 'link' },
    //  { icon: 'icon-chart-bar', name: 'RANCHI Monthlyreport', state: '/ranchi/ranchireports', type: 'link' },        
     
    ]
  },
  {
    name: 'RANCHI',
    type: 'dropDown',
    icon: 'icon-layers1',
    sub: [
  {
    name: 'Villages',
    type: 'dropDown',
    icon: 'icon-layers1',
    sub: [
    {
        name: 'BIHINDA', 
        type: 'dropDown',
        icon: 'icon-layers1',
        sub: [
          { icon: 'icon-home', name: 'BIHINDA Dashboard', state: '/ranchi/bihindadashboard', type: 'link' },
          { icon: 'icon-chart-bar', name: 'BIHINDA Monthlyreport', state: '/ranchi/bihindareports', type: 'link' },  
          // { icon: 'icon-zap', name: 'BIHINDACustomDateReport', state: '/ranchi/bihindacustomdatereports', type: 'link' },      
        ]
      },
      {
        name: 'KHARBANDA', 
        type: 'dropDown',
        icon: 'icon-layers1',
        sub: [
          { icon: 'icon-home', name: 'KHARBANDA Dashboard', state: '/ranchi/kharbandadashboard', type: 'link' },
          { icon: 'icon-chart-bar', name: 'KHARBANDA Monthlyreport', state: '/ranchi/kharbandareports', type: 'link' },        
          // { icon: 'icon-zap', name: 'KHARBANDA CustomDateReport', state: '/ranchi/kharbandacustomdatereports', type: 'link' },
        ]
      },

      {
        name: 'DONGRA', 
        type: 'dropDown',
        icon: 'icon-layers1',
        sub: [
          { icon: 'icon-home', name: 'DONGRA Dashboard', state: '/ranchi/dongradashboard', type: 'link' },
          { icon: 'icon-chart-bar', name: 'DONGRA Monthlyreport', state: '/ranchi/dongrareports', type: 'link' },        
          // { icon: 'icon-zap', name: 'DONGRA CustomDateReport', state: '/ranchi/dongracustomdatereports', type: 'link' },
        ]
      },

      
      {
        name: 'NAYADIH', 
        type: 'dropDown',
        icon: 'icon-layers1',
        sub: [
          { icon: 'icon-home', name: 'NAYADIH Dashboard', state: '/ranchi/nayadihdashboard', type: 'link' },
          { icon: 'icon-chart-bar', name: 'NAYADIH Monthlyreport', state: '/ranchi/nayadihreports', type: 'link' },        
          // { icon: 'icon-zap', name: 'NAYADIH CustomDateReport', state: '/ranchi/nayadihcustomdatereports', type: 'link' },
        ]
      },

      
      {
        name: 'CHATRO', 
        type: 'dropDown',
        icon: 'icon-layers1',
        sub: [
          { icon: 'icon-home', name: 'CHATRO Dashboard', state: '/ranchi/chatrodashboard', type: 'link' },
          { icon: 'icon-chart-bar', name: 'CHATRO Monthlyreport', state: '/ranchi/chatroreports', type: 'link' },        
          // { icon: 'icon-zap', name: 'CHATRO CustomDateReport', state: '/ranchi/chatrocustomdatereports', type: 'link' },
        ]
      },

      {
        name: 'CHOIRA PUNADIH TOLA ', 
        type: 'dropDown',
        icon: 'icon-layers1',
        sub: [
          { icon: 'icon-home', name: 'CHOIRA PUNADIH TOLA  Dashboard', state: '/ranchi/choiradashboard', type: 'link' },
          { icon: 'icon-chart-bar', name: 'CHOIRA PUNADIH TOLA  Monthlyreport', state: '/ranchi/choirareports', type: 'link' },        
          // { icon: 'icon-zap', name: 'CHOIRA PUNADIH TOLA  CustomDateReport', state: '/ranchi/choiracustomdatereports', type: 'link' },
        ]
      },
    ]
    },
    ]
    },
    ]











andhrapradeshMenu: IMenuItem2[]=[
  
{
name: 'ANDHRA PRADESH',
type: 'dropDown',
icon: 'icon-layers1',
sub: [
{
name: 'MVS',
type: 'dropDown',
icon: 'icon-layers1',
sub: [
{
    name: 'ELAPROLU', 
    type: 'dropDown',
    icon: 'icon-layers1',
    sub: [
      { icon: 'icon-home', name: 'ELAPROLU Dashboard', state: '/evidence/elaproludashboard', type: 'link' },
      { icon: 'icon-chart-bar', name: 'ELAPROLU Monthlyreport', state: '/evidence/elaprolureports', type: 'link' },    
      { icon: 'icon-zap', name: 'ELAPROLU CustomDateReport', state: '/evidence/elaprolucustomdatereports', type: 'link' },                
    ]
  },
  {
    name: 'SUNNAMPADU', 
    type: 'dropDown',
    icon: 'icon-layers1',
    sub: [
      { icon: 'icon-home', name: 'SUNNAMPADU Dashboard', state: '/evidence/sunnampadudashboard', type: 'link' },
      { icon: 'icon-chart-bar', name: 'SUNNAMPADU Monthlyreport', state: '/evidence/sunnampadureports', type: 'link' },  
      { icon: 'icon-zap', name: 'SUNNAMPADU CustomDateReport', state: '/evidence/sunnampaducustomdatereports', type: 'link' },                      
    ]
  },
  {
    name: 'RANGAPURAM', 
    type: 'dropDown',
    icon: 'icon-layers1',
    sub: [
      { icon: 'icon-home', name: 'RANGAPURAM Dashboard', state: '/evidence/rangapuramdashboard', type: 'link' },
      { icon: 'icon-chart-bar', name: 'RANGAPURAM Monthlyreport', state: '/evidence/rangapuramreports', type: 'link' }, 
      { icon: 'icon-zap', name: 'RANGAPURAM CustomDateReport', state: '/evidence/rangapuramcustomdatereports', type: 'link' },                       
    ]
  },
         
]
},




{
name: 'SVS', 
type: 'dropDown',
icon: 'icon-layers1',
sub: [
  {
    name: 'KOTIKALAPUDI', 
    type: 'dropDown',
    icon: 'icon-layers1',
    sub: [
      { icon: 'icon-home', name: 'KOTIKALAPUDI Dashboard', state: '/evidence/kotikalapudidashboard', type: 'link' },
      { icon: 'icon-chart-bar', name: 'KOTIKALAPUDI Monthlyreport', state: '/evidence/kotikalapudireports', type: 'link' },   
      { icon: 'icon-zap', name: 'KOTIKALAPUDI CustomDateReport', state: '/evidence/kotikalapudicustomdatereports', type: 'link' },                 
    ]
  },
  {
    name: 'GOPANNAPALEM', 
    type: 'dropDown',
    icon: 'icon-layers1',
    sub: [
      { icon: 'icon-home', name: 'GOPANNAPALEM Dashboard', state: '/evidence/gopannapalemdashboard', type: 'link' },
      { icon: 'icon-chart-bar', name: 'GOPANNAPALEM Monthlyreport', state: '/evidence/gopannapalemreports', type: 'link' },   
      { icon: 'icon-zap', name: 'GOPANNAPALEM CustomDateReport', state: '/evidence/gopannapalemcustomdatereports', type: 'link' },                 
    ]
  },
  {
    name: 'MADEPALLI', 
    type: 'dropDown',
    icon: 'icon-layers1',
    sub: [
      { icon: 'icon-home', name: 'MADEPALLI Dashboard', state: '/evidence/madepallidashboard', type: 'link' },
      { icon: 'icon-chart-bar', name: 'MADEPALLI Monthlyreport', state: '/evidence/madepallireports', type: 'link' }, 
      { icon: 'icon-zap', name: 'MADEPALLI CustomDateReport', state: '/evidence/madepallicustomdatereports', type: 'link' },                   
    ]
  },
]
},
]
},
]













  officeMenu: IMenuItem1[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/office/officedashboard', type: 'link' },
      ]
    },
  ]



  madhyapradeshMenu: IMenuItem3[]=[
    {
      name: 'MADHYA PRADESH',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
    {
      name: 'MVS',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
      {
          name: 'SINGPUR', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'SINGPUR Dashboard', state: '/evidence/singpurdashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'SINGPUR Monthlyreport', state: '/evidence/singpurreports', type: 'link' },  
            { icon: 'icon-zap', name: 'SINGPUR CustomDateReport', state: '/evidence/singpurcustomdatereports', type: 'link' },      
          ]
        },
        {
          name: 'AMEERGANJ', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'AMEERGANJ Dashboard', state: '/evidence/ameerganjdashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'AMEERGANJ Monthlyreport', state: '/evidence/ameerganjreports', type: 'link' },        
            { icon: 'icon-zap', name: 'AMEERGANJ CustomDateReport', state: '/evidence/ameerganjcustomdatereports', type: 'link' },
          ]
        },
               
      ]
    },

  {
      name: 'INTERMEDIATARY',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        {
          name: 'KANKARIYA', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'KANKARIYA Dashboard', state: '/evidence/kankariyadashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'KANKARIYA Monthlyreport', state: '/evidence/kankariyareports', type: 'link' },   
            { icon: 'icon-zap', name: 'KANKARIYA CustomDateReport', state: '/evidence/kankariyacustomdatereports', type: 'link' },           
          ]
        },]},


    {
      name: 'SVS', 
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        {
          name: 'KANASIYA', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'KANASIYA Dashboard', state: '/evidence/kanasiyadashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'KANASIYA Monthlyreport', state: '/evidence/kanasiyareports', type: 'link' },    
            { icon: 'icon-zap', name: 'KANASIYA CustomDateReport', state: '/evidence/kanasiyacustomdatereports', type: 'link' },          
          ]
        },
        {
          name: 'BARKHEDA', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'BARKHEDA Dashboard', state: '/evidence/barkhedadashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'BARKHEDA Monthlyreport', state: '/evidence/barkhedareports', type: 'link' },  
            { icon: 'icon-zap', name: 'BARKHEDA CustomDateReport', state: '/evidence/barkhedacustomdatereports', type: 'link' },      
          ]
        },
        {
          name: 'PACHAMA', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'PACHAMA Dashboard', state: '/evidence/pachamadashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'PACHAMA Monthlyreport', state: '/evidence/pachamareports', type: 'link' },
            { icon: 'icon-zap', name: 'PACHAMA CustomDateReport', state: '/evidence/pachamacustomdatereports', type: 'link' },       
                 
          ]
        },
        {
          name: 'BARKHEDI', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'BARKHEDI Dashboard', state: '/evidence/barkhedidashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'BARKHEDI Monthlyreport', state: '/evidence/barkhedireports', type: 'link' },  
            { icon: 'icon-zap', name: 'BARKHEDI CustomDateReport', state: '/evidence/barkhedicustomdatereports', type: 'link' },      
          ]
        },
        {
          name: 'SHYAMPUR', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'SHYAMPUR Dashboard', state: '/evidence/shyampurdashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'SHYAMPUR Monthlyreport', state: '/evidence/shyampurreports', type: 'link' },  
            { icon: 'icon-zap', name: 'SHYAMPUR CustomDateReport', state: '/evidence/shyampurcustomdatereports', type: 'link' },            
          ]
        },
        {
          name: 'LANCHOR', 
          type: 'dropDown',
          icon: 'icon-layers1',
          sub: [
            { icon: 'icon-home', name: 'LANCHOR Dashboard', state: '/evidence/lanchordashboard', type: 'link' },
            { icon: 'icon-chart-bar', name: 'LANCHOR Monthlyreport', state: '/evidence/lanchorreports', type: 'link' },  
            { icon: 'icon-zap', name: 'LANCHOR CustomDateReport', state: '/evidence/lanchorcustomdatereports', type: 'link' },            
          ]
        },
      ]
    },
  ]
},

]


  

  ahpwoodsMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'dropDown',
      icon: 'icon-home',
      sub: [
       
        { icon: 'icon-bar-chart-2', name: 'Dashboard', state: '/ahpwoods/ahpwoodsdashboard', type: 'link' },
      
      ]
    },
   // customer
    {
      name: 'Customer',
      type: 'dropDown',
      icon: 'icon-users',
      sub: [
        { icon: 'icon-user', name: 'Customer List', state: '/zenpadmin/zencustomerlist', type: 'link' },
       
      ]
    },
  //  customer
   // master
    {
      name: 'Master',
      type: 'dropDown',
      icon: 'icon-layers1',
      sub: [
        { icon: 'icon-zap', name: 'Meter Master', state: '/ahpwoods/ahpwoodsmetermaster', type: 'link' },
        { icon: 'icon-zap', name: 'Active/InActive Meters ', state: '/ahpwoods/ahpwoodsactiveinactivemeters', type: 'link' },
        { icon: 'icon-zap', name: 'Assign/UnAssign Meters ', state: '/ahpwoods/ahpwoodsassignunassignmeters', type: 'link' },
        { icon: 'icon-zap', name: 'Meter Status', state: '/ahpwoods/ahpwoodsmeterstatus', type: 'link' },
      ]
    },
   // master
    //Tanker Management
    // {
    //   name: 'Tanker Management',
    //   type: 'dropDown',
    //   icon: 'icon-layers1',
    //   sub: [
    //     { icon: 'icon-alert-triangle', name: 'Add Vendor', state: '/padmin/addvendor', type: 'link' },
    //     { icon: 'icon-alert-triangle', name: 'Tanker Management', state: '/padmin/tankermanagement', type: 'link' },
       
    //   ]
    // },
    //Tanker Management
   // Billing
    // {
    //   name: 'Billing',
    //   type: 'dropDown',
    //   icon: 'icon-file',
    //   sub: [
    //     { icon: 'icon-file', name: 'Billing history', state: '/admin/customerbills', type: 'link' },
        
       
    //   ]
    // },
    //Billing
   // Helpdesk
    {
      name: 'Helpdesk',
      type: 'dropDown',
      icon: 'icon-help-circle',
      sub: [
        { icon: 'icon-help-circle', name: 'Helpdesk List', state: '/ahpwoods/ahpwoodshelpdesk', type: 'link' },
              
      ]
    },
   // Helpdesk
    //Reports
    {
      name: 'Reports',
      type: 'dropDown',
      icon: 'icon-chart-bar',
      sub: [
        { icon: 'icon-chart-bar', name: 'customer wise reports', state: '/ahpwoods/ahpwoodsreports', type: 'link' },
        // { icon: 'icon-chart-bar', name: 'Wing wise reports', state: '/admin/wingwisereports', type: 'link' },
         { icon: 'icon-chart-bar', name: 'Month wise reports', state: '/ahpwoods/ahpwoodsmonthwisereports', type: 'link' },
          { icon: 'icon-chart-bar', name: 'Custom date reports', state: '/ahpwoods/ahpwoodscustomdatereports', type: 'link' },
        //  { icon: 'icon-chart-bar', name: 'SAP Daily Report Export', state: '/admin/dailyreports', type: 'link' },
        //  { icon: 'icon-chart-bar', name: 'Daily export reports', state: '/admin/dailyexportreports', type: 'link' },
              
      ]
    },
    //Reports

    
  ]

  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  menuItems1 = new BehaviorSubject<IMenuItem[]>(this.zenadminMenu);
  menuItems2 = new BehaviorSubject<IMenuItem[]>(this.superadminMenu);
  menuItems3 = new BehaviorSubject<IMenuItem[]>(this.adminMenu);
  menuItems4 = new BehaviorSubject<IMenuItem[]>(this.admin1Menu);
  menuItems5 = new BehaviorSubject<IMenuItem[]>(this.newMenu);

  menuItems6 = new BehaviorSubject<IMenuItem[]>(this.operationsMenu);
  menuItems7 = new BehaviorSubject<IMenuItem[]>(this.assamMenu);
 menuItems8 = new BehaviorSubject<IMenuItem[]>(this.volvoMenu);
 menuItems9 = new BehaviorSubject<IMenuItem[]>(this.bharatiyaMenu);
 menuItems10 = new BehaviorSubject<IMenuItem[]>(this.adityabirla);
 menuItems11 = new BehaviorSubject<IMenuItem[]>(this.nikoohomes);
 menuItems12 = new BehaviorSubject<IMenuItem[]>(this.ahadopus);
 menuItems13 = new BehaviorSubject<IMenuItem[]>(this.nagalandMenu);
 menuItems14 = new BehaviorSubject<IMenuItem[]>(this.namdhariMenu);
 menuItems15 = new BehaviorSubject<IMenuItem[]>(this.bmsplendorMenu);
 //menuItems16 = new BehaviorSubject<IMenuItem[]>(this.sptMenu);
// menuItems17 = new BehaviorSubject<IMenuItem[]>(this.excellentiaMenu);
 menuItems18 = new BehaviorSubject<IMenuItem[]>(this.gujaratMenu);
 menuItems19 = new BehaviorSubject<IMenuItem[]>(this.evidenceMenu);
 menuItems20 = new BehaviorSubject<IMenuItem[]>(this.officeMenu);
 menuItems21 = new BehaviorSubject<IMenuItem[]>(this.ahpwoodsMenu);
 menuItems22 = new BehaviorSubject<IMenuItem[]>(this.eadminaddMenu);
 menuItems23 = new BehaviorSubject<IMenuItem3[]>(this.madhyapradeshMenu);
 menuItems24 = new BehaviorSubject<IMenuItem[]>(this.andhrapradeshMenu);
 menuItems25= new BehaviorSubject<IMenuItem[]>(this.ranchiMenu);
 menuItems26= new BehaviorSubject<IMenuItem[]>(this.pondugalaMenu);
 menuItems27= new BehaviorSubject<IMenuItem[]>(this.jindalMenu);

 menuItems29= new BehaviorSubject<IMenuItem[]>(this.tripuraMenu);
 menuItems30= new BehaviorSubject<IMenuItem[]>(this.ahpa01Menu);
 menuItems31= new BehaviorSubject<IMenuItem[]>(this.ahpa02Menu);
 menuItems32= new BehaviorSubject<IMenuItem[]>(this.ahpa03Menu);
 menuItems33= new BehaviorSubject<IMenuItem[]>(this.ahpa04Menu);
 menuItems34= new BehaviorSubject<IMenuItem[]>(this.ahpa05Menu);
 menuItems35= new BehaviorSubject<IMenuItem[]>(this.chirugoraMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  menuItems1$ = this.menuItems1.asObservable();
  menuItems2$ = this.menuItems2.asObservable();
  menuItems3$ = this.menuItems3.asObservable();
  menuItems4$ = this.menuItems4.asObservable();
  menuItems5$ = this.menuItems5.asObservable();
  menuItems6$ = this.menuItems6.asObservable();
  menuItems7$ = this.menuItems7.asObservable();
  menuItems8$ = this.menuItems8.asObservable();
  menuItems9$ = this.menuItems9.asObservable();
  menuItems10$ = this.menuItems10.asObservable();
  menuItems11$ = this.menuItems11.asObservable();
  menuItems12$ = this.menuItems12.asObservable();
  menuItems13$ = this.menuItems13.asObservable();
  menuItems14$ = this.menuItems14.asObservable();
  menuItems15$ = this.menuItems15.asObservable();
 // menuItems16$ = this.menuItems16.asObservable();
 // menuItems17$ = this.menuItems17.asObservable();
  menuItems18$ = this.menuItems18.asObservable();
  menuItems19$ = this.menuItems19.asObservable();
  menuItems20$ = this.menuItems20.asObservable();
  menuItems21$ = this.menuItems21.asObservable();
  menuItems22$ = this.menuItems22.asObservable();
  menuItems23$ = this.menuItems23.asObservable();
  menuItems24$ = this.menuItems24.asObservable();
  menuItems25$ = this.menuItems25.asObservable();
  menuItems26$ = this.menuItems26.asObservable();
  menuItems27$ = this.menuItems27.asObservable();
  menuItems29$ = this.menuItems29.asObservable();
  menuItems30$ = this.menuItems30.asObservable();
  menuItems31$ = this.menuItems31.asObservable();
  menuItems32$ = this.menuItems32.asObservable();
  menuItems33$ = this.menuItems33.asObservable();
  menuItems34$ = this.menuItems34.asObservable();
  menuItems35$ = this.menuItems35.asObservable();
  // You can customize this method to supply different menu for
  // different user type.
  // publishNavigationChange(menuType: string) {
  //   switch (userType) {
  //     case 'admin':
  //       this.menuItems.next(this.adminMenu);
  //       break;
  //     case 'user':
  //       this.menuItems.next(this.userMenu);
  //       break;
  //     default:
  //       this.menuItems.next(this.defaultMenu);
  //   }
  // }
 
}