import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';

import { HttpClientModule } from '@angular/common/http'; 
import { DropdownModule } from "primeng/dropdown";
import * as $ from 'jquery';
import { BsDatepickerModule } from "ngx-bootstrap";
import { ExcelService } from './shared/services/excel.service';
import { AuthService } from './auth.service';
import { AuthInterceptor } from '../app/core/authinterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataLayerService } from './shared/services/data-layer.service';
import { Data_Service } from './shared/services/data_service';
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { DatePipe } from '@angular/common';
import { ImageUploadModule } from "ng2-imageupload";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
 import { NgxSpinnerModule } from "ngx-spinner";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxGaugeModule } from 'ngx-gauge';
import { NgxEchartsModule } from 'ngx-echarts';
import { PushNotificationService } from './shared/services/push-notification.service';
import { MarkerService } from './marker.service';
import { PopupService } from './popup.service';
// import { ExozenComponent } from './views/exozen/exozen.component';
//import { PushNotificationService } from './shared/services/push-notification.service';
//import { environment } from 'src/environments/environment';



@NgModule({
  declarations: [
    AppComponent,
    // ExozenComponent,
   
   
    
  ],
  imports: [
    BrowserModule,
    SharedModule,
    NgMultiSelectDropDownModule,
    NgxSkeletonLoaderModule,
    ImageUploadModule,
    NgxSpinnerModule,
   NgxGaugeModule,
    HttpClientModule,
    DropdownModule,
    Ng2SearchPipeModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    // InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    AppRoutingModule,
    NgxEchartsModule,
  
    ToastrModule.forRoot()
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }, DataLayerService,ExcelService, AuthService,Data_Service,DatePipe,PushNotificationService,MarkerService,PopupService],
  bootstrap: [AppComponent]
})
export class AppModule { }
