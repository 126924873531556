import { Component, OnInit, HostListener, EventEmitter, OnDestroy } from '@angular/core';
import { NavigationService, IMenuItem } from '../../services/navigation.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Utils } from '../../utils';

import * as $ from 'jquery';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
	selectedItem: IMenuItem;
	projectformongo;
	newlogin;
	Storage: any = {};
    routeSubscriber: Subscription;
	nav: IMenuItem[];

	constructor(
		public router: Router,
		public navService: NavigationService
	) {
        if(window && !window['routerEvent']) {
            window['routerEvent'] = new EventEmitter();
        }
		this.projectformongo = localStorage.getItem("projectname");	
		this.newlogin = localStorage.getItem("newlogin");	
		this.Storage = localStorage.getItem("role");

    }

	ngOnInit() {
        $.getScript('./assets/js/app-sidebar.js');

		this.updateSidebar();
        // CLOSE SIDENAV ON ROUTE CHANGE
        this.routeSubscriber = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((routeChange) => {
				this.closeChildNav();
				if (Utils.isMobile()) {
					this.navService.sidebarState.sidenavOpen = false;
                }
                if(window) {
                    window['routerEvent'].emit(routeChange);
                }
            });

			if(this.Storage === "padmin"){
				if(this.projectformongo ==="Marina_Square"){
					this.navService.menuItems$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				} else if(this.projectformongo ==="burjdaman"){
					this.navService.menuItems3$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}
				else if(this.projectformongo ==="CSIR"){
					this.navService.menuItems7$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag(); 
					})
				}
				else if(this.projectformongo ==="AdityaBirla"){
					this.navService.menuItems10$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}
				else if(this.projectformongo ==="nikko_homes"){
					this.navService.menuItems11$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}
				else if(this.projectformongo ==="Ahad_opus"){
					this.navService.menuItems12$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}
				else if(this.projectformongo ==="Operation_manager"){
					this.navService.menuItems6$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}
				else if(this.projectformongo ==="Gujarat"){
					this.navService.menuItems18$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}
				else if(this.projectformongo ==="Volvonew"){
					this.navService.menuItems8$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}
				else if(this.projectformongo ==="Namdharis"){
					this.navService.menuItems14$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}
				else if(this.projectformongo ==="ExozenOffice"){
					this.navService.menuItems20$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}
				else if(this.projectformongo ==="Nagaland"){
					this.navService.menuItems13$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}
				else if(this.projectformongo ==="Evidence_Action"){
					this.navService.menuItems19$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}

				
				else if(this.projectformongo ==="Evidence_Action_Admin"){
					this.navService.menuItems22$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}



				else if(this.projectformongo ==="Madhya_pradesh"){
					this.navService.menuItems23$
					
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}

				else if(this.projectformongo ==="Andhra_pradesh"){
					this.navService.menuItems24$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}


				
				else if(this.projectformongo ==="Ranchi"){
					this.navService.menuItems25$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}

			

				
				else if(this.projectformongo ==="Pondugala"){
					this.navService.menuItems26$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}

				else if(this.projectformongo ==="Jindal"){
					this.navService.menuItems27$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}


				

				else if(this.projectformongo ==="Tripura"){
					this.navService.menuItems29$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}
				
				else if(this.projectformongo ==="Bm_Splendors_Park"){
					this.navService.menuItems15$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}


				else if(this.projectformongo ==="Ahpa01"){
					this.navService.menuItems30$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}


				
				else if(this.projectformongo ==="Ahpa02"){
					this.navService.menuItems31$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}


					
				else if(this.projectformongo ==="Ahpa03"){
					this.navService.menuItems32$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}


				else if(this.projectformongo ==="Ahpa04"){
					this.navService.menuItems33$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}


				else if(this.projectformongo ==="Ahpa05"){
					this.navService.menuItems34$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}



				else if(this.projectformongo ==="Chirugora"){
					this.navService.menuItems35$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}

			

				// else if(this.projectformongo ==="sethna_power_tower"){
				// 	this.navService.menuItems16$
				// 	.subscribe((items) => {
				// 		this.nav = items;
				// 		this.setActiveFlag();
				// 	})
				// }
				// else if(this.projectformongo ==="Ahad_Excellencia"){
				// 	this.navService.menuItems17$
				// 	.subscribe((items) => {
				// 		this.nav = items;
				// 		this.setActiveFlag();
				// 	})
				// }
				
				else if(this.projectformongo ==="Bharatiya_City"){
					this.navService.menuItems9$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}
				else if(this.newlogin ==="New"){
					this.navService.menuItems5$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}
				
				else {
					this.navService.menuItems1$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
				}
			} else if (this.Storage === "admin") {
				this.navService.menuItems2$
					.subscribe((items) => {
						this.nav = items;
						this.setActiveFlag();
					})
			  }
		
		
		
    }

    ngOnDestroy() {
        if(this.routeSubscriber) {
            this.routeSubscriber.unsubscribe();
        }
    }

	selectItem(item) {
		this.navService.sidebarState.childnavOpen = true;
		this.selectedItem = item;
		this.setActiveMainItem(item);
	}

	previewSecondaryItem(item) {
		this.navService.sidebarState.childnavOpen = true;
		this.selectedItem = item;
	}

	closeChildNav() {
		this.navService.sidebarState.childnavOpen = false;
		this.setActiveFlag();
	}

	onClickChangeActiveFlag(item) {
		this.setActiveMainItem(item);
	}
	setActiveMainItem(item) {
		this.nav.forEach(item => {
			item.active = false;
		});
		item.active = true;
	}

	setActiveMouseoverMainItem(item) {
		this.nav.forEach(item => {
			item.active = false;
		});
		item.active = true;
	}

	setActiveFlag() {
		if (window && window.location) {
			let activeRoute = window.location.hash || window.location.pathname;
			this.nav.forEach(item => {
				item.active = false;
				if (activeRoute.indexOf(item.state) !== -1) {
                    item.active = true;
                    this.selectedItem = item;
				}
				if (item.sub) {
					item.sub.forEach(subItem => {
						if (activeRoute.indexOf(subItem.state) !== -1) {
							item.active = true;
                            this.selectedItem = item;
                        }
					})
				}
			})
		}
	}

	updateSidebar() {
		if (Utils.isMobile()) {
			this.navService.sidebarState.sidenavOpen = false;
		} else {
			this.navService.sidebarState.sidenavOpen = true;
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.updateSidebar();
	}


}
