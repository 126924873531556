import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';

const routes: Routes = [
  {
    path: '', 
    redirectTo: '/sessions/signin',
    pathMatch: 'full'
  },
   
  {
    path: '',
    component: AuthLayoutComponent, 
    children: [
      {
        path: 'sessions',
        loadChildren: './views/sessions/sessions.module#SessionsModule'
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: './views/others/others.module#OthersModule'
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGaurd],
    children: [
     
      {
        path: 'padmin',
        loadChildren: './views/padmin/padmin.module#PadminModule'
      },
      {
        path: 'excellentia',
        loadChildren: './views/excellentia/excellentia.module#ExcellentiaModule'
      },
      {
        path: 'office',
        loadChildren: './views/office/office.module#OfficeModule'
      },
      {
        path: 'gujarat',
        loadChildren: './views/gujarat/gujarat.module#GujaratModule'
      },
      {
        path: 'zenpadmin',
        loadChildren: './views/zenpadmin/zenpadmin.module#ZenpadminModule'
      },
      {
        path: 'sadmin',
        loadChildren: './views/sadmin/sadmin.module#SadminModule'
      },


    
      {
        path: 'admin',
        loadChildren: './views/admin/admin.module#AdminModule'
      },
      { 
        path: 'admin1',
        loadChildren: './views/admin/admin.module#AdminModule'
      },
      {  
        path: 'new',
        loadChildren: './views/new/new.module#NewModule'
      }, 
      {
        path: 'adityabirla',
        loadChildren: './views/adityabirla/adityabirla.module#AdityabirlaModule'
      },
      {
        path: 'ahadopus',
        loadChildren: './views/ahadopus/ahadopus.module#AhadopusModule'
      },
      {
        path: 'nikoohomes',
        loadChildren: './views/nikoohomes/nikoohomes.module#NikoohomesModule'
      },

    

      { 
        path: 'volvo',
        loadChildren: './views/volvo/volvo.module#VolvoModule'
      },
      {
        path: 'bharatiya',
        loadChildren: './views/bharatiyacity/bharatiyacity.module#BharatiyacityModule'
      },
      {
        path: 'Namdharis',
        loadChildren: './views/namdhari/namdhari.module#NamdhariModule'
      },
      {
        path: 'nagaland',
        loadChildren: './views/nagaland/nagaland.module#NagalandModule'
      },
      {
        path: 'bmsplendor',
        loadChildren: './views/bmsplendor/bmsplendor.module#BmsplendorModule'
      },


      {
        path: 'ahpa01',
        loadChildren: './views/ahpa01/ahpa01.module#Ahpa01Module'
      },

     
      {
        path: 'evidence',
        loadChildren: './views/evidence/evidence.module#EvidenceModule'
      },

      {
        path: 'ranchi',
        loadChildren: './views/ranchi/ranchi.module#RanchiModule'
      },

      {
        path: 'pondugala',
        loadChildren: './views/pondugala/pondugala.module#PondugalaModule'
      },

      {
        path: 'tripura',
        loadChildren: './views/tripura/tripura.module#TripuraModule'
      },

      {
        path: 'jindal',
        loadChildren: './views/jindal/jindal.module#JindalModule'
      },
      
      {
        path: 'chirugora',
        loadChildren: './views/chirugora/chirugora.module#ChirugoraModule'
      },
      // {
      //   path: 'a01',
      //   loadChildren: './views/a01/a01.module#a01Module'
      // },
        {
          path: 'madhyapradesh',
          redirectTo: '',
          pathMatch: 'full',
        },
      

      {
        path: 'andhrapradesh',
        loadChildren: './views/evidence/evidence.module#EvidenceModule'
      },
  
      {
        path: 'eadminaddcustomer',
        loadChildren: './views/eadminaddcustomer/eadminaddcustomer.module#EadminaddcustomerModule'
      },

     


    


     
     
  
 
      /*{
        path: 'spt',
        loadChildren: './views/spt/spt.module#SptModule'
      },*/
      
    ]
  }, 
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
