import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanActivate {
  Storage:any={}
  project_name;
  constructor(
    private router: Router,
    private auth: AuthService
  ) {
    this.Storage = localStorage.getItem("role");
    // console.log(this.Storage)
    this.project_name = localStorage.getItem("projectname");
   }

  canActivate() {
    if(this.auth.authenticated) {
     
      // console.log("hiii")
      // if (this.Storage === "padmin") {
      //   if (this.project_name === "Eltizam") {
      //     this.router.navigateByUrl('/padmin/dashboard')
      //   } else {
      //     this.router.navigateByUrl('/dashboard/v3')
      //   }
      // } 
      // else if (this.Storage === "admin") {
      //   this.router.navigateByUrl('/dashboard/v2')
      // }
      return true;
    } 
    else {
      
      this.router.navigateByUrl('/sessions/signin');
    }
  }
}
