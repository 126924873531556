import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as ExcelJS from "exceljs/dist/exceljs"
import { reduce } from 'rxjs/operators';
declare const ExcelJS :any;
@Injectable({providedIn: 'root'})
export class ExcelService {

  constructor() { } 
  workbook: ExcelJS.Workbook;
  worksheet: any;
public exportAsExcelFile1(json, excelFileName: string): void {

    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Report');
    //Add Header Row
    let headerRow = worksheet.addRow(json.header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.font = {
        bold: true,
      
      }
    });
    console.log("Excel Exported Data: ", json.body);
    let data = json.body;
    worksheet.addRows(data);
  //   let row = worksheet.addRow();
  //   let errorcode = row.getCell(19);
  // let color = 'FF99FF99';
  // if (errorcode.value = '0') {
  //   color = 'FF9999'
  // }
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
      // FileSaver.saveAs(blob, excelFileName + 'export' + new Date().getTime() + EXCEL_EXTENSION);
      FileSaver.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
  //   let workbook = new ExcelJS.Workbook();
  //  / let worksheet = workbook.addWorksheet('Report');
    //Add Header Row
  
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet',worksheet);
 
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   let row = worksheet.addRow(json);
  //   let errorcode = row.getCell(19);
  // let color = 'FF99FF99';
  // if (errorcode.value > '00') {
  //   color = 'FF9999'
  // }
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
 
    
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
   // FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
   FileSaver.saveAs(data, fileName +  EXCEL_EXTENSION);
  }

}