import { Injectable } from '@angular/core';
import { LocalStoreService } from './local-store.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
 import { Http,Headers } from '@angular/http';
import * as jwt_decode from 'jwt-decode';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticated: boolean = false;
 // private url: string = 'api/auth';
 private headers = new HttpHeaders().set('Content-Type', 'application/json'); 
  //private headers = new Headers({ 'Content-Type': 'application/json' });
  private token = localStorage.getItem("key");
  Storage:any={}
  project_name;
  constructor(
    private store: LocalStoreService,
    private router: Router,
   private httpClient: HttpClient
  ) {
    this.checkAuth();
    this.Storage = localStorage.getItem("role");
    // console.log(this.Storage)
    this.project_name = localStorage.getItem("projectname");
  }
  // getToken(): string {
  //   return localStorage.getItem("key");
  // }

  // setToken(token: string): void {
  //   localStorage.setItem("key", token);
  // }

  // getTokenExpirationDate(token: string): Date {
  //   const decoded = jwt_decode(token);

  //   if (decoded.exp === undefined) return null;

  //   const date = new Date(0);
  //   date.setUTCSeconds(decoded.exp);
  //   return date;
  // }
  // isTokenExpired(token?: string): boolean {
  //   if (!token) token = this.getToken();
  //   if (!token) return true;

  //   const date = this.getTokenExpirationDate(token = localStorage.getItem("key"));
  //   if (date === undefined) return false;
  //   return !(date.valueOf() > new Date().valueOf());
  // }

  // login(user): Promise<string> {
  //   return this.httpClient
  //     .post(`${this.url}/login`, JSON.stringify(user), { headers: this.headers })
  //     .toPromise()
  //     .then(res => res.text());
  // }
  // test(){
  //   var data = {
  //     'username':"",
  //     'password':""
  //   }
  //   this.httpClient.post('',data).subscribe((res) =>{
  //     console.log(res);
  //   }),
  //   (err) =>{
  //     console.log(err)
  //   };
    
  // }

  checkAuth() {
    
    this.authenticated = this.store.getItem('demo_login_status');
   
  }

  getuser() {
    return of({});
  }

  signin() {
   
    this.authenticated = true;
    
    this.store.setItem('demo_login_status', true);
   
  }
  signout() {
    this.authenticated = false;
    this.store.setItem('demo_login_status', false);
    this.router.navigateByUrl('/sessions/signin')
  }
}
