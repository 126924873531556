// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  configFirebase: {
    apiKey: "AIzaSyDAGStZ-AY_GJ0Sz075Pzy55I66uOQJTqI",
  authDomain: "zengap-8f03e.firebaseapp.com",
  databaseURL: "https://zengap-8f03e.firebaseio.com",
  projectId: "zengap-8f03e",
  storageBucket: "zengap-8f03e.appspot.com",
  messagingSenderId: "901329018977",
  appId: "1:901329018977:web:dcded71fd1379a980fca20",
  measurementId: "G-BNQNH0CN8M"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
