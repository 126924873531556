import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { map, filter, retry, catchError } from 'rxjs/operators';
import { configData } from '../../core/config';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class Data_Service {
  public response: any;
  public location:any;
  public buildingname:any;
  public address:any;
  public userData: any;
  //public apiUrl = 'https://zenapi.co.in/'
  constructor(
    private http: HttpClient,
    private toastr: ToastrService
  ) { }

public headerstr = new HttpHeaders({ "Authorization": localStorage.getItem("key") })

  //==========================================
  getmethod(url): Observable<any> {
    //   console.log(localStorage.getItem("token"))
      if (null === localStorage.getItem("key")) {
       this.headerstr = new HttpHeaders({ "Authorization": localStorage.getItem("token") })
      }
    return this.http.get( url, { headers: this.headerstr }).pipe(
      map(resp => this.response = resp)
    )
  }
  postmethod(data,url): Observable<any> {
    if (null === localStorage.getItem("key")) {
        this.headerstr = new HttpHeaders({ "Authorization": localStorage.getItem("token") })
       }
    return this.http.post(url,data, { headers: this.headerstr }).pipe(
      map(resp => this.response = resp)
    )
  }
  deletemethod(url): Observable<any> {
    //   console.log(localStorage.getItem("token"))
      if (null === localStorage.getItem("key")) {
       this.headerstr = new HttpHeaders({ "Authorization": localStorage.getItem("token") })
      }
    return this.http.delete( url, { headers: this.headerstr }).pipe(
      map(resp => null)
    )
  }
  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error.status); // for demo purposes only
    return Promise.reject(error.status);
  }
  //=========================================
  globalErrorHandler(erroCode) {
    if (erroCode === 400) {
      // this.doErrorAlert("ios-close-circle-outline", "Access is denied due to invalid PIN");
      // this._alert.showMessage("Invalid Details", "error");
    } else if (erroCode === 500) {
      //  this._alert.showMessage("Unable to process your request and try again", "error");
    } else if (erroCode === 0) {
      this.toastr.warning(
        "No Internet connection found from server side. Check your connection or please try again",
        "error"
      );
    } else if (erroCode === 503) {
      // this._alert.showMessage("HTTP Error 503. The service is unavailable.", "error");
    } else if (erroCode === 401) {
      this.toastr.warning(
        "Authorization has been denied for invalid credentials please give validate credentials.",
        "error"
      );
    }
  }











  


}
